import { useMediaQuery, useTheme } from 'stylesHooks';

const useDevice = () => {
	const theme = useTheme();

	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return {
		isDesktop,
		isTablet,
		isMobile,
		isTabletDevice: isMobile || isTablet,
	};
};

export default useDevice;
