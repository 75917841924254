import { BoxOverflow } from 'styles';
import TableBody from './ClaimsTable/TableBody.component';
import TableHeader from './ClaimsTable/TableHeader.component';

const ClaimsTable = () => {
	return (
		<BoxOverflow variant="x">
			<TableHeader />
			<TableBody />
		</BoxOverflow>
	);
};

export default ClaimsTable;
