import dayjs from 'dayjs';
import { FC, useState } from 'react';

import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';
import { getLanguageStorage } from 'utils/localStorage';
import { dayjsForMaterial } from 'utils/utils';
import { Stack, Typography } from 'styles';
import palette from 'styles/theme/Palette';

type Props = {
	value: dayjs.Dayjs | null;
	onChange: (value: dayjs.Dayjs | null) => void;
	label: string;
	error?: boolean;
	errorText: string;
	maxDate?: dayjs.Dayjs | undefined;
	minDate?: dayjs.Dayjs | undefined;
};

const DatePickerField: FC<Props> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const {
		value,
		onChange,
		label,
		error,
		errorText,
		maxDate: maxDateNF,
		minDate: mindateNF,
	} = props;

	const minDate = dayjsForMaterial(mindateNF);
	const maxDate = dayjsForMaterial(maxDateNF);

	const [invalidDate, setInvalidDate] = useState(false);

	return (
		<DesktopDatePicker
			value={value}
			views={[]}
			mask="__/__/____"
			label={label}
			onChange={e => {
				const newDate = dayjsForMaterial(e);
				const isValidDate =
					!newDate ||
					(newDate.isValid() &&
						(!maxDate || maxDate.diff(newDate) >= 0) &&
						(!minDate || newDate.diff(minDate) >= 0));
				onChange(isValidDate && newDate ? newDate : null);
				setInvalidDate(!isValidDate);
			}}
			minDate={minDate}
			maxDate={maxDate}
			renderInput={params => (
				<Stack gap={'9px'} minHeight={{ sm: '114px', lg: '130px' }}>
					<TextField
						{...params}
						error={!!error || invalidDate}
						variant="filled"
						autoComplete={'off'}
						inputProps={{
							...params.inputProps,
							placeholder:
								getLanguageStorage() !== 'en-us'
									? tClientLabels('commons.ddmmyyyy')
									: tClientLabels('commons.mmddyyyy'),
						}}
					/>
					{error && (
						<Typography variant="value1" color={palette.error.main}>
							{errorText}
						</Typography>
					)}
				</Stack>
			)}
			disableOpenPicker
			InputProps={{
				disableUnderline: true,
			}}
		/>
	);
};

export default DatePickerField;
