import {
	AnyAction,
	createSlice,
	isAnyOf,
	isRejected,
	isRejectedWithValue,
} from '@reduxjs/toolkit';

import { ButtonPropsVariantOverrides } from '@mui/material/Button';
import { OverridableStringUnion } from '@mui/types';
import { getCookiesContent } from 'feature/cookies/cookiesSlice';
import { getDocuments } from 'feature/documents/documentsSlice';
import {
	CHECK_CONNECTION_ERROR,
	COOKIES_DIALOG,
	ERROR_MESSAGE,
	NO_PROVIDER,
} from 'types/dialogtypes';

interface IDataLimitationsDialog {
	title: string;
	content: string;
}

interface IState {
	type: string;
	buttonClose: boolean;
	open: boolean;
	title: string;
	content: string;
	caption: string;
	messageCode: '';
	labelCancel: string;
	labelConfirm: string;
	multi: boolean;
	data: IDataLimitationsDialog[] | [];
	variantButtonCancel: OverridableStringUnion<
		'text' | 'outlined' | 'contained',
		ButtonPropsVariantOverrides
	>;
}

const initialState: IState = {
	open: false,
	title: '',
	content: '',
	caption: '',
	messageCode: '', //this is an alternative to content. with this we need to extract the message from textsLocalesSlice
	labelCancel: '',
	labelConfirm: '',
	variantButtonCancel: 'outlined',
	multi: false,
	data: [],
	type: '',
	buttonClose: false,
};

const dialogSlice = createSlice({
	name: 'dialog',
	initialState,
	reducers: {
		setDialogOpen: (state, { payload }) => {
			state.open = payload;
		},
		setInjectionState: (state, { payload }) => {
			state.title = payload.title;
			state.content = payload.content;
			state.caption = payload.caption;
			state.labelCancel = payload.labelCancel;
			state.labelConfirm = payload.labelConfirm;
			state.variantButtonCancel = payload.variantButtonCancel;
			state.multi = payload?.multi ? payload.multi : false;
			state.data = payload?.data ? payload.data : [];
			state.type = payload.type;
			state.buttonClose = payload.buttonClose;
		},
		resetDialogConfirm: () => initialState,
	},
	extraReducers: builder => {
		//manage errors popup
		builder
			.addCase(getDocuments.fulfilled, (state, { payload }) => {
				// check added if the API call returns successfully
				// but it can happen that the payload is empty
				if (!payload) {
					return {
						...state,
						title: 'dialog.confirm.error.title',
						content: 'dialog.confirm.error.content',
						caption: '',
						multi: false,
						buttonClose: true,
						open: true,
						type: ERROR_MESSAGE,
					};
				}
			})
			.addCase(getCookiesContent.fulfilled, (state, { payload }) => {
				const errorCode = payload?.data?.errorCode;
				switch (errorCode) {
					default:
						return {
							...state,
							open: true,
							title: payload?.title,
							content: payload?.content,
							caption: '',
							multi: false,
							buttonClose: false,
							labelCancel: 'dialog.buttons.reject',
							labelConfirm: 'dialog.buttons.confirm',
							variantButtonCancel: 'outlined',
							type: COOKIES_DIALOG,
						};
				}
			})
			.addMatcher(
				isAnyOf(isRejected, isRejectedWithValue),
				(state: IState, response: AnyAction) => {
					if (response.meta.aborted) {
						return;
					}

					const errorCode = response.payload?.data?.errorCode;

					switch (response.type) {
						case 'healthCheck/healthCheck/rejected':
							return {
								...state,
								open: true,
								title: 'dialog.confirm.noVpn.title',
								content: response.payload?.data?.message
									? response.payload?.data?.message
									: 'dialog.confirm.noVpn.content',
								caption: '',
								multi: false,
								buttonClose: false,
								labelCancel: '',
								variantButtonCancel: 'outlined',
								labelConfirm: 'commons.retry',
								type: CHECK_CONNECTION_ERROR,
							};
						case 'user/getProfile/rejected':
							return {
								...state,
								open: true,
								title: 'dialog.confirm.noProviderAssociated.title',
								content: response.payload?.data?.message
									? response.payload?.data?.message
									: 'dialog.confirm.noProviderAssociated.content',
								caption: '',
								multi: false,
								buttonClose: false,
								labelCancel: '',
								variantButtonCancel: 'outlined',
								labelConfirm: 'commons.retry',
								type: NO_PROVIDER,
							};
						default:
							return {
								...state,
								open: true,
								title: 'dialog.confirm.error.title',
								content: '',
								caption: '',
								messageCode: errorCode,
								multi: false,
								buttonClose: true,
								labelCancel: '',
								variantButtonCancel: 'outlined',
								type: ERROR_MESSAGE,
								labelConfirm: 'commons.buttons.close',
							};
					}
				},
			);
	},
});

export const { setDialogOpen, setInjectionState, resetDialogConfirm } =
	dialogSlice.actions;
export default dialogSlice.reducer;
