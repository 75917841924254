import { Dayjs } from 'dayjs';
import { ThunkApiType } from 'types/storeTypes';
import { formatDayjsForBE } from 'utils/utils';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const searchMemberThunk = async (
	props: { values: { [key: string]: string | number | Dayjs | null } },
	thunkAPI: ThunkApiType,
) => {
	const getSearchMemberURL = `/member/search`;
	const newProps = {
		...props.values,
		date_of_service: formatDayjsForBE(
			props.values.date_of_service as Dayjs | null,
		),
	};

	try {
		const response = await authFetch.post(getSearchMemberURL, newProps);
		return {
			...response.data,
			inputProps: newProps,
		};
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
