import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAttachment, IState } from 'types/Attachment';
import {
	gerAttachmentPreview,
	getExistingPrescriptionsListThunk,
} from './attachmentsThunk';

export const getExistingPrescriptionsList = createAsyncThunk(
	'attachments/getExistingPrescriptionsList',
	getExistingPrescriptionsListThunk,
);

export const getAttachmentPreview = createAsyncThunk(
	'attachments/getAttachmentPreview',
	gerAttachmentPreview,
);

const initialState: IState = {
	loaded: false,
	existingPrescriptionsList: [],
	selectedAttachment: undefined,
	attachmentPreview: undefined,
};

const attachmentsSlice = createSlice({
	name: 'attachments',
	initialState,
	reducers: {
		setSelectedAttachment: (state, { payload }: PayloadAction<IAttachment>) => {
			state.selectedAttachment = payload;
		},
		setAttachmentPreview: (state, { payload }: PayloadAction<string>) => {
			state.attachmentPreview = payload;
		},
		resetSelectedAttachment: state => {
			return {
				...state,
				selectedAttachment: undefined,
				attachmentPreview: undefined
			};
		},
		resetAttachmentsState: () => initialState,
	},
	extraReducers: builder => {
		builder
			.addCase(getExistingPrescriptionsList.fulfilled, (state, { payload }) => {
				return {
					...state,
					existingPrescriptionsList: payload,
				};
			})
			.addCase(
				getAttachmentPreview.fulfilled,
				(state, { payload }: PayloadAction<string>) => {
					state.attachmentPreview = payload;
				},
			);
	},
});

export const {
	setSelectedAttachment,
	setAttachmentPreview,
	resetSelectedAttachment,
	resetAttachmentsState,
} = attachmentsSlice.actions;

export default attachmentsSlice.reducer;
