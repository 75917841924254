import { Box, styled, Typography as TypographyMui, TypographyProps as TypographyPropsMui } from "@mui/material";
import * as React from "react";

type TypographyProps = TypographyPropsMui & {
  label: string;
  value: string;
  variant?: string;
  children?: React.ReactNode
}

const LabelTypography = styled(TypographyMui)({});
const ValueTypography = styled(TypographyMui)({});

const TypographyBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  flexWrap: 'wrap'
})

const CompositeTypography: React.FC<TypographyProps> = props => {
  return <TypographyBox {...props}>
    <LabelTypography  {...props} variant={'body'} sx={{ paddingRight: props.children ? '8px' : '0px' }}>
      {`${props.label}:`}
    </LabelTypography>
    {props.children
      ? props.children
      : <ValueTypography sx={{ ml: 1 }}
        variant={props.variant}>
        {props.value}
      </ValueTypography>}
  </TypographyBox>
}

export default CompositeTypography;