import { useTranslate } from 'stylesHooks';
import { Box, Stack, Typography } from 'styles';
import palette from 'styles/theme/Palette';
import { IConfiguration } from 'types/LocationInformation';

interface PrintConfigurationProps {
	label: string;
	data: IConfiguration[];
	width?: string;
}

const PrintConfiguration: React.FC<PrintConfigurationProps> = ({
	label,
	data,
	width,
}) => {
	const { t: tDbLabels } = useTranslate('dbLabels');
	return (
		<Box
			display="flex"
			flexDirection="column"
			gap={{ sm: '0.625rem' }}
			width={width || '100%'}
		>
			<Typography pl={2} variant="bodyLink">
				{label}
			</Typography>
			<Stack
				borderRadius={1}
				height={'100%'}
				direction="row"
				alignItems="center"
				sx={{ backgroundColor: palette.white.main }}
				px={2}
				py={3}
				flexWrap="wrap"
			>
				<Typography variant="value2">
					{data.map(el => tDbLabels(el.label)).join(', ')}
				</Typography>
			</Stack>
		</Box>
	);
};

export default PrintConfiguration;
