import { IUserError, IUserProfile } from 'types/User';
import {
	cleanupStorage,
	getCookiesInLocalStorage,
	setCookiesInLocalStorage,
} from 'utils/localStorage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getProfileThunk, getRolesThunk, logoutThunk } from './userThunk';

interface State {
	error: IUserError;
	cookiesConfirmed: 'y' | 'n' | undefined; // this way we have all the options. yes/no/not yet selected
	profile: IUserProfile | null;
	roles: {
		code: string;
		name: string;
		features: { code: string; name: string }[];
	}[];
	features: { code: string; name: string }[]; // i will save them also here for an easyer access
	locationSelected: string | null;
	activeButton: boolean;
	logoutMsal: boolean;
}

interface IFeature {
	code: string;
	name: string;
}

const initialState: State = {
	error: {
		status: '',
		code: '',
		message: '',
	},
	cookiesConfirmed: getCookiesInLocalStorage(),
	profile: null,
	roles: [],
	features: [],
	locationSelected: null,
	activeButton: false,
	logoutMsal: false,
};

export const getProfile = createAsyncThunk('user/getProfile', getProfileThunk);
export const getRoles = createAsyncThunk('user/getRoles', getRolesThunk);
export const logout = createAsyncThunk('user/logout', logoutThunk);

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setLocationSelected: (state, { payload }) => {
			return { ...state, locationSelected: payload };
		},
		setActiveButton: (state, { payload }) => {
			state.activeButton = payload;
		},
		setCookies: (state, action) => {
			setCookiesInLocalStorage(action.payload);
			return {
				...state,
				cookiesConfirmed: action.payload,
			};
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getRoles.fulfilled, (state, { payload }) => {
				let features = payload.roles.reduce(
					(sum: IFeature[], el: { features: IFeature[] }) => [
						...sum,
						...el.features,
					],
					[],
				);

				features = features.filter(
					(feat: IFeature, index: number) =>
						features.findIndex((el: IFeature) => el.code === feat.code) === index,
				);

				return { ...state, roles: payload.roles, features };
			})
			.addCase(getProfile.fulfilled, (state, { payload }) => {
				return { ...state, profile: payload };
			})
			.addCase(logout.fulfilled, (state, { meta }) => {
				cleanupStorage();
				const { instance, accounts } = meta.arg;
				instance.clearCache();
				instance.logoutRedirect({
					account: instance.getAccountByHomeId(accounts[0].homeAccountId),
				});

				return { ...state, logoutMsal: true };
			});
	},
});

export const { setCookies, setLocationSelected, setActiveButton } =
	userSlice.actions;
export default userSlice.reducer;
