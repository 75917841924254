import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { thunkLoadingParams } from 'feature/progressIndicator/progressIndicatorSlice';
import { ThunkApiType } from 'types/storeTypes';

export type loginUserInput = {
	username: string;
	password: string;
	storageType: 'sessionStorage' | 'localStorage';
} & thunkLoadingParams;

export const getProfileThunk = async (_: string, thunkAPI: ThunkApiType) => {
	const getProfileURL = '/provider/profile';
	try {
		const response = await authFetch.get(getProfileURL);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getRolesThunk = async (_: string, thunkAPI: ThunkApiType) => {
	try {
		const response = await authFetch.get('/features/providers');
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const logoutThunk = async (
	_: { instance: IPublicClientApplication; accounts: AccountInfo[] },
	thunkAPI: ThunkApiType,
) => {
	try {
		const response = await authFetch.post('/logout', {});
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
