import { Box, CircularProgress, Typography } from 'styles';
import { MIME_TYPES } from 'types/Document';

import { useTranslate } from 'stylesHooks';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { TRANSLATION_KEYS } from 'utils/constants';
pdfjs.GlobalWorkerOptions.workerSrc = '/plugins/pdfjs/pdf.worker.min.js';

type Props = {
	mime: string;
	url: string;
	name: string;
	isPrescription?: boolean;
};

const FileRenderer = ({ mime, url, name, isPrescription }: Props) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const [numPages, setNumPages] = useState<number>();

	const URL_RAW = isPrescription
		? url
		: `${process.env.REACT_APP_API_URL}/cms${url}`;

	// check for PDFs
	if (MIME_TYPES.PDF.includes(mime)) {
		return (
			<Document
				file={URL_RAW}
				loading={
					<Box display={'flex'} width={'100%'} justifyContent={'center'} py={'8px'}>
						<CircularProgress />
					</Box>
				}
				onLoadSuccess={({ numPages }) => setNumPages(numPages)}
			>
				{Array.apply(null, Array(numPages))
					.map((_, i) => i + 1)
					.map((page, pageIndex) => (
						<Page
							key={pageIndex}
							renderTextLayer={false}
							renderAnnotationLayer={false}
							pageNumber={page}
						/>
					))}
			</Document>
		);
	}

	// check for images
	if (MIME_TYPES.IMAGE.includes(mime)) {
		return (
			<Box
				component="img"
				sx={{
					mb: { xl: '-6px', lg: '-6px', md: '-6px', sm: '-6px', xs: '-6px' }, // added to avoid the bottom spacing given by a possible scrollbar for overflow x
					width: {
						xl: '800px',
						lg: '800px',
						md: '728px',
						sm: '728px',
						xs: '728px',
					},
				}}
				alt={name}
				src={URL_RAW}
				height={'auto'}
			/>
		);
	}

	// all the rest
	return (
		<Typography variant="body">
			{tClientLabels('commons.noPreviewAvailable')}
		</Typography>
	);
};

export default FileRenderer;
