import {
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	Select,
} from 'styles';

import { SelectChangeEvent } from '@mui/material';
import { useTranslate } from 'stylesHooks';
import SelectItem from 'styles/select/SelectItem';
import { TRANSLATION_KEYS } from 'utils/constants';

export interface Day {
	id: number;
	checked: boolean;
}

interface SelectDaysProp {
	label: string;
	selectedDay: number[];
	handleSelectedDays: (event: SelectChangeEvent<any | unknown>) => void;
	checkFields: boolean;
}

const DAYS = [0, 1, 2, 3, 4, 5, 6];

const SelectDays: React.FC<SelectDaysProp> = ({
	label,
	selectedDay,
	handleSelectedDays,
	checkFields,
}) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	return (
		<>
			<FormControl
				sx={{
					width: { sm: '100%', lg: '235px' },
				}}
			>
				<InputLabel>{label}</InputLabel>
				<Select
					multiple
					variant="filled"
					name={label}
					value={selectedDay}
					renderValue={(selected: any) => {
						return selected
							.map((item: number) =>
								tClientLabels(`commons.daysOfWeek.d${item}`).substring(0, 3),
							)
							.join('-');
					}}
					onChange={handleSelectedDays}
					error={checkFields && !selectedDay.length}
					disableUnderline
				>
					{DAYS.map((e, index) => {
						return (
							<SelectItem
								value={e}
								sx={{ height: 24, py: 2 }}
								variant="checkbox"
								key={`day_${index}`}
							>
								<FormControlLabel
									label=""
									control={
										<Checkbox
											label={tClientLabels(`commons.daysOfWeek.d${e}`)}
											checked={selectedDay.indexOf(e) > -1}
										/>
									}
								/>
							</SelectItem>
						);
					})}
				</Select>
			</FormControl>
		</>
	);
};
export default SelectDays;
