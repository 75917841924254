import { FC, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslate } from 'stylesHooks';
import CheckSubmitImage from 'assett/icons/check_eligibility_image.svg';
import { resetMember } from 'feature/members/memberSlice';
import { resetMembers } from 'feature/members/membersSlice';
import { AppDispatch, RootState } from 'store/store';
import Box from 'styles/box/Box';
import CardMedia from 'styles/card/CardMedia';
import FormControlLabel from 'styles/form/FormControlLabel';
import Radio from 'styles/radio/Radio';
import RadioGroup from 'styles/radio/RadioGroup';
import Stack from 'styles/stack/Stack';
import { TRANSLATION_KEYS } from 'utils/constants';
import DateOfService from './components/DateOfService.component';
import FormFiscalCode from './forms/FormFiscalCode';
import FormMemberData from './forms/FormMemberData';
import FormMemberID from './forms/FormMemberID';
import ListMembers from './list/ListMembers';
import useDevice from 'hooks/DesktopDevice.hook';
import { BoxOverflow } from 'styles';

const CheckSubmitClaim: FC = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const dispatch = useDispatch<AppDispatch>();
	const { members } = useSelector((store: RootState) => store.members);
	const { dateOfService } = useSelector((store: RootState) => store.form);
	const [filter, setFilter] = useState<string | null>(null);
	const { isDesktop } = useDevice();

	useEffect(() => {
		dispatch(resetMembers());
		dispatch(resetMember());
	}, [filter, dateOfService, dispatch]);

	return (
		<Fragment>
			<Box sx={{ height: '100%' }}>
				<Stack
					direction={'row'}
					height={'100%'}
					justifyContent={'space-between'}
					overflow="hidden"
				>
					<BoxOverflow
						variant="y"
						sx={{
							width: { sm: !!members?.hits.length ? 'auto' : '100%', lg: 900 },
							mt: { sm: 0.625, lg: 7.37 },
							mb: { sm: 0.625, lg: 6.25 },
						}}
					>
						<Stack width="100%" direction="row" gap="30px">
							<Stack
								sx={{
									width: { sm: 340, lg: 368 },
									mb: 2,
								}}
								rowGap={{ sm: 3.75, lg: 6.25 }}
							>
								<DateOfService />
								<RadioGroup
									name="filter"
									value={filter}
									defaultValue={filter}
									onChange={e => setFilter(e.target.value)}
									sx={{ rowGap: 2.5 }}
								>
									<FormControlLabel
										sx={{ width: '100%' }}
										value="inputByFiscalCode"
										label={tClientLabels(
											'check-and-submit.searchLabels.searchByFiscalCode',
										)}
										control={<Radio />}
										componentsProps={{ typography: { variant: 'value2' } }}
									/>
									<FormControlLabel
										value="inputByMemberID"
										label={tClientLabels(
											'check-and-submit.searchLabels.searchByMemberID',
										)}
										control={<Radio />}
										componentsProps={{ typography: { variant: 'value2' } }}
									/>
									<FormControlLabel
										value="inputByMemberName"
										label={tClientLabels(
											'check-and-submit.searchLabels.searchByMemberName',
										)}
										control={<Radio />}
										componentsProps={{ typography: { variant: 'value2' } }}
									/>
								</RadioGroup>
							</Stack>
							<Box
								width={{
									sm: '340px',
									lg: '368px',
								}}
							>
								{filter === 'inputByFiscalCode' && <FormFiscalCode />}
								{filter === 'inputByMemberID' && <FormMemberID />}
								{filter === 'inputByMemberName' && <FormMemberData />}
							</Box>
						</Stack>
						<Stack mt={{ sm: '30px', lg: '50px' }}>
							{!!members?.hits.length && <ListMembers />}
						</Stack>
					</BoxOverflow>
					{isDesktop && (
						<Stack
							height={'100%'}
							alignSelf={'end'}
							justifyContent={'end'}
							alignItems={'end'}
							display={{ sm: !!members?.hits.length ? 'none' : 'flex', lg: 'flex' }}
						>
							<CardMedia
								component="img"
								sx={{
									width: { sm: 371.95, lg: '90%' },
									height: { sm: 350.93, lg: 'auto' },
									mb: { sm: 5, lg: 6.25 },
								}}
								image={CheckSubmitImage}
							/>
						</Stack>
					)}
				</Stack>
			</Box>
		</Fragment>
	);
};
export default CheckSubmitClaim;
