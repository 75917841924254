import {
	Tooltip as TooltipMui,
	TooltipProps as TooltipPropsMui,
} from '@mui/material';
import { forwardRef } from 'react';

import styled from 'styled-components';

type TooltipProps = TooltipPropsMui;

const TooltipStyled = styled(TooltipMui)<TooltipProps>``;

const Tooltip: React.FC<TooltipProps> = forwardRef((props, ref) => {
	return (
		<TooltipStyled {...props} ref={ref}>
			{props.children}
		</TooltipStyled>
	);
});

export default Tooltip;
