export const TRANSLATION_KEYS = {
	SEARCH_LOCATION: 'searchLocation',
	SEARCH_LOCATION_FILTERS: 'searchLocation.filters',
	PROVIDER_DETAILS: 'providerDetails',
	DIALOG: 'dialog',
	COMMONS: 'commons',
	CLAIMS: 'claims',
	OONCLAIMS: 'oonClaims',
	SPECIAL_OFFERS: 'specialOffers',
	PRESCRIPTIONS: 'prescriptions',
	MY_BENEFITS: 'myBenefits',
	MY_PROFILE: 'myProfile',
	DB_LABELS: 'dbLabels',
	ERRORS: 'errorsMessages',
	CLIENT_LABELS: 'clientLabels',
	CLIENT_LABELS_INDEX: 'clientLabels.Index',
	UPLOAD_FILE_DIALOG: 'uploadFileDialog',
	INDEX: 'Index',
	CONTACT_US: 'contactUs',
	KNOW_BEFORE_YOU_GO: 'knowBeforeYouGo',
	SERVICE: 'service',
};
