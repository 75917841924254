export type DocType = 'prescription' | 'receipt' | 'rxAttestation';

export enum UploadFileStep {
	CHOOSE = 'CHOOSE',
	LOADING = 'LOADING',
	COMPLETE = 'COMPLETE',
}

export type HttpMethod = 'POST' | 'PUT' | 'DELETE' | 'GET' | 'GET_ALL';
export interface IUploadState {
	step: UploadFileStep;
	uploadPercentage: number;
	errorCode: number;
	errorMessage: string;
	documentTypeSelected: DocType;
	file: File | null;
	filePromise: any;
	documentId: number | null
}
export interface IUploadedFileResponse {
	id: number;
	fileName: string;
}


export type iFile = 'fromLibrary' | 'fromPhoto' | 'fromFile';

export interface IUploadAction {
	icon: string;
	label: string;
	actionType: iFile;
}
