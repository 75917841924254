import { useTranslate } from 'stylesHooks';
import useDialogLocation, { CHANGE_LOCATION } from 'hooks/DialogLocation.hook';

import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Button from 'styles/button/Button';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import CompositeTypography from 'styles/typography/CompositeTypography.styles';
import { TRANSLATION_KEYS } from 'utils/constants';
import useDevice from 'hooks/DesktopDevice.hook';

const UserProvider: React.FC = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { profile } = useSelector((store: RootState) => store.user);
	const dialogLocation = useDialogLocation();
	const { isDesktop } = useDevice();

	const handleChangeLocation = () => {
		dialogLocation.open(CHANGE_LOCATION);
	};

	return (
		<Paper variant="light">
			<Stack flexGrow={1} direction={'row'} gap={1.8}>
				<CompositeTypography
					label={tClientLabels('dashboard.providerPanel.providerId')}
					value={profile?.providerId ? profile?.providerId : '-'}
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
				/>
				<CompositeTypography
					label={tClientLabels('dashboard.providerPanel.location')}
					value={profile?.location ? profile?.location : '-'}
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
				/>
			</Stack>
			<Stack flexGrow={0}>
				<Button variant="linkDark" onClick={() => handleChangeLocation()}>
					{tClientLabels('dashboard.providerPanel.changeLocation')}
				</Button>
			</Stack>
		</Paper>
	);
};

export default UserProvider;
