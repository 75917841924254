import {
	TextField as TextFieldMui,
	TextFieldProps as TextFieldPropsMui,
} from '@mui/material';
import { FC, forwardRef } from 'react';
import styled from 'styled-components';

export type TextFieldProps = TextFieldPropsMui;

const StyledTextField = styled(TextFieldMui)({});

const TextField: FC<TextFieldProps> = forwardRef((props, ref) => {
	return (
		<StyledTextField
			ref={ref}
			InputProps={{
				disableUnderline: true,
			}}
			variant="filled"
			{...props}
		>
			{props.children}
		</StyledTextField>
	);
});

export default TextField;
