import ClaimsOperationIcon, {
	Sign,
} from 'components/commons/ClaimIcons/ClaimsOperationIcon.component';
import {
	Divider,
	Paper,
	Table,
	TableBody,
	TableBox,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from 'styles';
import { formatPrice, getShortDate } from 'utils/utils';

import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Stack } from 'styles';
import TableFooterBox from 'styles/box/TableFooterBox';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';

const ClaimPaymentDetails = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { claimData: claim, errorCode } = useSelector(
		(store: RootState) => store.claim,
	);
	const {
		amount: { components },
	} = claim;
	const {
		amount: {
			total: {
				retailAmount,
				contractualAdjustmentAmount,
				allowablePlanPay,
				memberPayAmount,
				total,
				grossRetail,
				inStorePromotion,
			},
		},
	} = claim;
	const rows = components;

	if (errorCode !== 0) {
		return null;
	}

	return (
		<TableBox>
			<TableContainer component={Paper} sx={{ pb: '20px' }}>
				<Table aria-label="customized table">
					<TableHead>
						<TableRow>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.serviceDate')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.serviceCode')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.st')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.grossRetail')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.MINUS} />
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.inStorePromotion')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.EQUAL} />
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.retailAmount')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.MINUS} />
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.contractaulAdjiustment')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.planPayment')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.PLUS} />
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.memberOutOfPocket')}
								</Typography>
							</TableCell>
							<TableCell align="center">
								<ClaimsOperationIcon sign={Sign.EQUAL} />
							</TableCell>
							<TableCell align="center">
								<Typography variant="value1">
									{tClientLabels('claim.table.total')}
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row: any, index: number) => {
							const {
								retailAmount,
								serviceId,
								serviceName,
								contractualAdjustmentAmount,
								planPayAmount,
								allowablePlanPay,
								memberPayAmount,
								total,
								grossRetail,
								inStorePromotion,
							} = row;

							return (
								<TableRow key={index}>
									<TableCell component="th" scope="row" align="center">
										<Typography variant="body">
											{getShortDate(claim.claimDate)}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body">{serviceId ? serviceId : '-'}</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{serviceName ? serviceName.substring(0, 1) : '-'}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{grossRetail ? formatPrice(grossRetail) : formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{inStorePromotion
												? formatPrice(inStorePromotion)
												: formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{retailAmount ? formatPrice(retailAmount) : formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{contractualAdjustmentAmount
												? formatPrice(contractualAdjustmentAmount)
												: formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{planPayAmount ? formatPrice(allowablePlanPay) : formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{memberPayAmount
												? formatPrice(memberPayAmount)
												: formatPrice('0.00')}
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										<Typography variant="body">
											{total ? formatPrice(total) : formatPrice('0.00')}
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}

						<TableRow>
							<TableCell colSpan={15}>
								<Divider light sx={{ width: '100%' }} />
							</TableCell>
						</TableRow>

						<TableRow sx={{ paddingBottom: '20px' }}>
							<TableCell
								component="th"
								scope="row"
								align="left"
								sx={{ paddingLeft: '30px !important', paddingBottom: '20px' }}
							>
								<Typography variant="bodyLink">
									{tClientLabels('claim.table.total')}:
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{grossRetail ? formatPrice(grossRetail) : formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{inStorePromotion
										? formatPrice(inStorePromotion)
										: formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{retailAmount ? formatPrice(retailAmount) : formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{contractualAdjustmentAmount
										? formatPrice(contractualAdjustmentAmount)
										: formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{allowablePlanPay
										? formatPrice(allowablePlanPay)
										: formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{memberPayAmount ? formatPrice(memberPayAmount) : formatPrice('0.00')}
								</Typography>
							</TableCell>
							<TableCell align="center"></TableCell>
							<TableCell align="center" sx={{ paddingBottom: '20px' }}>
								<Typography variant="bodyLink">
									{total ? formatPrice(total) : formatPrice('0.00')}
								</Typography>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<TableFooterBox>
				<Typography variant="bodyLink">
					{tClientLabels('claim.legend.title')}
				</Typography>
				<Stack direction={'row'} sx={{ marginLeft: 2 }} gap={2}>
					<Typography variant="body">
						{tClientLabels('claim.legend.exam')}
					</Typography>
					<Typography variant="body">
						{tClientLabels('claim.legend.lens')}
					</Typography>
					<Typography variant="body">
						{tClientLabels('claim.legend.contacts')}
					</Typography>
					<Typography variant="body">
						{tClientLabels('claim.legend.frame')}
					</Typography>
					<Typography variant="body">
						{tClientLabels('claim.legend.misc')}
					</Typography>
				</Stack>
			</TableFooterBox>
		</TableBox>
	);
};

export default ClaimPaymentDetails;
