import {
	ThemeProvider as MuiThemeProvider,
	StyledEngineProvider,
} from '@mui/material/styles';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { CssBaseline as MuiCssBaseline } from '@mui/material';
import { deepmerge } from '@mui/utils';
import {
	DevTools,
	FormatSimple,
	Tolgee,
	TolgeeProvider,
	TreeTranslationsData,
} from '@tolgee/react';
import { Frame } from 'appContainer/Frame';
import { msalConfig } from 'config/msalConfig';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ErrorPage from 'pages/Error.page';
import { FC } from 'react';
import CacheBuster from 'react-cache-buster';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { themeSystem } from 'styles/theme/ThemeSystem';
import { injectReduxStore } from 'utils/axios';
import { getLocaleLanguage } from 'utils/utils';
import packageInfo from '../package.json';
import { store } from './store/store';
import { theme } from './styles/theme/ThemeStyle';

injectReduxStore(store);

const pca = new PublicClientApplication(msalConfig);

const loadTexts = async (lng: string, nameSpace: string) => {
	const dbTexts = await fetch(
		`${process.env.REACT_APP_ASSET_URL}i18n/tolgee/${nameSpace}/${lng}`,
		{
			method: 'GET',
			mode: 'cors', // no-cors, *cors, same-origin
			headers: {
				'Content-Type': 'application/json',
				'Mercurio-Client-Type': 'provider-portal',
			},
		},
	);

	if (dbTexts.status > 399) {
		console.error('ERROR: couldnt load translations');
		throw Error('ERROR: translations arent working!!!!!');
	}

	const dbTextsRes = await dbTexts.json();
	return dbTextsRes as unknown as {
		errors: TreeTranslationsData;
		dbLabels: TreeTranslationsData;
		clientLabels: TreeTranslationsData;
	};
};

const staticData = {
	'it:errors': () => loadTexts('it', 'errors').then(el => el),
	'it:dbLabels': () => loadTexts('it', 'dbLabels').then(el => el),
	'it:clientLabels': () => loadTexts('it', 'clientLabels').then(el => el),
	'en:errors': () => loadTexts('en', 'errors').then(el => el),
	'en:dbLabels': () => loadTexts('en', 'dbLabels').then(el => el),
	'en:clientLabels': () => loadTexts('en', 'clientLabels').then(el => el),
	'es:errors': () => loadTexts('es', 'errors').then(el => el),
	'es:dbLabels': () => loadTexts('es', 'dbLabels').then(el => el),
	'es:clientLabels': () => loadTexts('es', 'clientLabels').then(el => el),
	'de:errors': () => loadTexts('de', 'errors').then(el => el),
	'de:dbLabels': () => loadTexts('de', 'dbLabels').then(el => el),
	'de:clientLabels': () => loadTexts('de', 'clientLabels').then(el => el),
};

export const App: FC = () => {
	const language = getLocaleLanguage() || 'en';
	dayjs.extend(utc);
	dayjs.extend(timezone);

	const isProduction = process.env.NODE_ENV === 'production';

	const tolgee = Tolgee().use(DevTools()).use(FormatSimple()).init({
		language,
		fallbackNs: 'clientLabels',
		apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
		apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
		staticData,
	});

	return (
		<CacheBuster
			currentVersion={packageInfo.version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
		>
			<Provider store={store}>
				<MsalProvider instance={pca}>
					<TolgeeProvider tolgee={tolgee}>
						<StyledEngineProvider injectFirst>
							<MuiThemeProvider theme={deepmerge(themeSystem, theme)}>
								<MuiCssBaseline />
								<StyledComponentsThemeProvider theme={theme}>
									<ErrorBoundary fallback={<ErrorPage />}>
										<Frame />
									</ErrorBoundary>
								</StyledComponentsThemeProvider>
							</MuiThemeProvider>
						</StyledEngineProvider>
					</TolgeeProvider>
				</MsalProvider>
			</Provider>
		</CacheBuster>
	);
}; /*  */
