import { MenuItem, MenuItemProps } from '@mui/material';
import { styled } from '@mui/system';
import * as React from 'react';

type BoxProps = MenuItemProps & {
	key: string;
	variant: 'checkbox' | 'radio';
	sx?: any;
};

const StyledSelectItem = styled(MenuItem, {
	shouldForwardProp: prop => prop !== 'sx',
	name: 'MuiSelectItem',
	slot: 'Root',
	overridesResolver: (props, styles) => [styles.root],
})<BoxProps>({});

const SelectItem: React.FC<BoxProps> = props => {
	return (
		<StyledSelectItem {...props} disableRipple disableGutters disableTouchRipple>
			{props.children}
		</StyledSelectItem>
	);
};

export default SelectItem;
