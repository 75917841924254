import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	getAvailableReasonsThunk,
	Reason,
	sendContactUsDataFormThunk,
} from './contactUsThunk';

interface IState {
	reasons: Reason[];
	isOpenSubmitAppeal: boolean;
}

const initialState: IState = {
	isOpenSubmitAppeal: false,
	reasons: [],
};

export const getAvailableReasons = createAsyncThunk(
	'contactUs/getAvailableReasons',
	getAvailableReasonsThunk,
);

export const sendContactUsDataForm = createAsyncThunk(
	'contactUs/sendContactUsDataForm',
	sendContactUsDataFormThunk,
);

const contactUsSlice = createSlice({
	name: 'contactUsSlice',
	initialState,
	reducers: {
		closeSubmitAppeal: state => {
			state.isOpenSubmitAppeal = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(
				getAvailableReasons.fulfilled,
				(state, { payload }: PayloadAction<{ data: any }>) => {
					if (payload?.data?.attributes?.reasons) {
						state.reasons = payload?.data?.attributes?.reasons;
					}
				},
			)
			.addCase(sendContactUsDataForm.fulfilled, state => {
				state.isOpenSubmitAppeal = true;
			});
	},
});
export const { closeSubmitAppeal } = contactUsSlice.actions;
export default contactUsSlice.reducer;
