import { ThunkApiType } from 'types/storeTypes';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { getLocaleLanguage } from 'utils/utils';

export const getCookiesThunk = async (_: string, thunkAPI: ThunkApiType) => {
	const lang = getLocaleLanguage();

	const getCookiesURL = `/cms/api/policies?filters[scope][%24eq]=cookie&locale=${lang}`;

	try {
		const response = await authFetch.get(getCookiesURL);
		const resp = response.data?.data;
		return resp ? resp[0]?.attributes : undefined;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
