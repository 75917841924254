import { ThunkApiType } from 'types/storeTypes';

export const healthCheckThunk = async (_: string, thunkAPI: ThunkApiType) => {
	try {
		const response = await fetch(process.env.REACT_APP_CHECK_VPN_URL!);

		return response.status < 400
			? true
			: thunkAPI.rejectWithValue({ response: 'dead ;(' });
	} catch (error: ThunkApiType) {
		return thunkAPI.rejectWithValue({ response: 'dead ;(' });
	}
};
