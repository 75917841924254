import ReactMarkdown from 'react-markdown';

function LinkRenderer(props: any) {
	return (
		<a href={props.href} target="_blank" rel="noreferrer" className="cookiesLink">
			{props.children}
		</a>
	);
}

export const MarkdownRenderer = ({ children }: { children: string | null }) => {
	return (
		<ReactMarkdown components={{ a: LinkRenderer }}>{children}</ReactMarkdown>
	);
};
