import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { searchLocationThunk } from './searchThunk';

interface IState {
	errorCode: number;
	errorMessage: string;
	location: ILocation[];
	searchLocationValue: string;
}
export interface ILocation {
	address: string;
	currentBrands: string[];
	currentLanguages: string[];
	currentServices: string[];
	name: string;
	number: string;
	openingsHour: string;
	position: number[];
	providerId: string;
	provider_logo: string;
	provider_master: string;
	provider_network: string;
	site_url: string;
	timestamp: string;
	zip: string;
}

const initialState: IState = {
	errorCode: 0,
	errorMessage: '',
	location: [],
	searchLocationValue: '',
};

export const searchLocation = createAsyncThunk(
	'search/searchLocation',
	searchLocationThunk,
);

const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setSearchLocationValue: (state, { payload }) => {
			state.searchLocationValue = payload;
		},
		resetLocation: state => {
			state.errorCode = 0;
			state.errorMessage = '';
			state.location = [];
		},
		resetSearch: state => {
			state.errorCode = 0;
			state.errorMessage = '';
			state.location = [];
			state.searchLocationValue = '';
		},
	},
	extraReducers: builder => {
		builder
			.addCase(searchLocation.pending, (state, payload) => {
				state.errorCode = 0;
				state.errorMessage = '';
			})
			.addCase(searchLocation.fulfilled, (state, { payload }) => ({
				...state,
				errorCode: 0,
				errorMessage: '',
				location: payload.providerLocations,
			}))
			.addCase(searchLocation.rejected, (state, { payload }: any) => {
				state.errorCode = payload.statusCode;
				state.errorMessage = payload.message;
			});
	},
});

export const { setSearchLocationValue, resetSearch, resetLocation } = searchSlice.actions;
export default searchSlice.reducer;
