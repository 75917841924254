import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IState, PAGE_LIMIT } from 'types/Payment';
import {
	getPaymentAssessmentFeeThunk,
	getPaymentDetailsThunk,
	getPaymentRunThunk,
} from './paymentThunk';

const initialState: IState = {
	errorCode: 0,
	errorMessage: '',
	paymentRun: {
		claims: [],
		pages: 1,
		page: 1,
		totalAmount: 0,
		paymentRunId: '',
		paymentRunDate: '',
	},
	paymentDetails: {
		detailType: '',
		from: {
			name: '',
			address: '',
			fiscalCode: '',
			tin: '',
		},
		to: {
			name: '',
			address: '',
			fiscalCode: '',
			tin: '',
		},
		paymentInfo: {
			invoiceDate: '',
			invoiceNumber: '',
			typeOfService: '',
			paymentTerms: '',
			service: '',
			quantity: 0,
		},
		paymentAmount: {
			paymentId: '',
			unitPrice: 0,
			amount: 0,
			subTotal: 0,
			vat: 0,
			total: 0,
		},
		paymentClaims: [],
		total: {
			claimId: 0,
			dateOfService: '',
			location: '',
			totalCharges: 0,
			contractualWriteOff: 0,
			memberResponsible: 0,
			claimPayment: 0,
			assessmentFee: 0,
			assessmentFeeTax: 0,
			other: 0,
			netDue: 0,
		},
	},
};

export const getPaymentRun = createAsyncThunk(
	'payment/getPaymentRun',
	getPaymentRunThunk,
);

export const getPaymentDetails = createAsyncThunk(
	'payment/getPaymentDetails',
	getPaymentDetailsThunk,
);

export const getPaymentAssessmentFee = createAsyncThunk(
	'payment/getPaymentAssessmentFee',
	getPaymentAssessmentFeeThunk,
);

const paymentSlice = createSlice({
	name: 'payment',
	initialState,
	reducers: {
		resetPayment: () => initialState,
		changePage: (state: IState, { payload }: PayloadAction<number>) => {
			state.paymentRun.page = payload;
		},
		setDetailType: (state: IState, { payload }: PayloadAction<string>) => {
			state.paymentDetails.detailType = payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getPaymentRun.fulfilled, (state, { payload }) => {
				const totalPages = Math.ceil(payload.claims.length / PAGE_LIMIT);
				state.paymentRun = payload;
				state.paymentRun.page = 1;
				state.paymentRun.pages = totalPages;
			})
			.addCase(getPaymentDetails.fulfilled, (state, { payload }) => {
				state.paymentDetails = payload;
			})
			.addCase(getPaymentAssessmentFee.fulfilled, (state, { payload }) => {
				state.paymentDetails = payload;
			})
			.addCase(getPaymentRun.rejected, (state, { payload }: any) => {
				state.errorCode = payload.status;
				state.errorMessage = payload.data.message;
			})
			.addCase(getPaymentDetails.rejected, (state, { payload }: any) => {
				state.errorCode = payload.status;
				state.errorMessage = payload.statusText;
			})
			.addCase(getPaymentAssessmentFee.rejected, (state, { payload }: any) => {
				state.errorCode = payload.status;
				state.errorMessage = payload.statusText;
			});
	},
});

export const { resetPayment, changePage, setDetailType } = paymentSlice.actions;
export default paymentSlice.reducer;
