import { useTranslate } from 'stylesHooks';
import {
	handleClickItem,
	handleSelectAll,
} from 'feature/locationInformation/locationInformationSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { Box, Checkbox, Chip, Stack, Typography } from 'styles';
import palette from 'styles/theme/Palette';

import { ConfigurationKey, IConfiguration } from 'types/LocationInformation';
import { TRANSLATION_KEYS } from 'utils/constants';

interface EditConfigurationProps {
	configurationKey: ConfigurationKey;
	label: string;
}

const EditConfiguration: React.FC<EditConfigurationProps> = ({
	label,
	configurationKey,
}) => {
	const { t: tDbLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	const dispatch = useDispatch<AppDispatch>();
	const { availableConfiguration, currentConfiguration } = useSelector(
		(store: RootState) => store.locationInformation,
	);

	const allCodeCurrentConfiguration = currentConfiguration[configurationKey].map(
		el => el.code,
	);

	const handleClick = (item: IConfiguration) => {
		dispatch(handleClickItem({ configurationItem: item, configurationKey }));
	};

	const handleClickSelectAll = () => {
		dispatch(handleSelectAll({ configurationKey }));
	};

	return (
		<Stack bgcolor={palette.white.main} p={2}>
			<Stack
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<Typography variant="bodyLink">{label}</Typography>
				<Checkbox
					checked={
						availableConfiguration[configurationKey].length ===
						currentConfiguration[configurationKey].length
					}
					onClick={handleClickSelectAll}
					label="Select All"
				/>
			</Stack>
			<Box
				sx={{
					height: '1px',
					backgroundColor: palette.grey4.main,
					marginTop: '15px',
					marginBottom: '20px',
				}}
			/>
			<Stack direction={'row'} gap={'5px'} flexWrap={'wrap'}>
				{availableConfiguration[configurationKey].map(
					(conf: IConfiguration, idx: number) => (
						<Chip
							key={idx}
							label={tDbLabels(conf.label)}
							$selected={allCodeCurrentConfiguration.indexOf(conf.code) > -1}
							onClick={() => handleClick(conf)}
						/>
					),
				)}
			</Stack>
		</Stack>
	);
};

export default EditConfiguration;
