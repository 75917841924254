import { ReactComponent as VisibileIcon } from 'assett/icons/eye_show_icon.svg';
import { Box, IconButton, ListItem, Radio, Stack, Typography } from 'styles';
import { useTranslate } from 'stylesHooks';
import { IAttachment } from 'types/Attachment';
import { TRANSLATION_KEYS } from 'utils/constants';
import { bytesToKilobytes, getShortDate } from 'utils/utils';

export type DocSelected = {
	id: number;
	fileName: string;
};

type Props = {
	doc: IAttachment;
	selectedPrescription: DocSelected | null;
	handleSelectPrescription: (docSelected: DocSelected) => void;
	handlePreviewPrescription: (doc: IAttachment) => void;
};

const PrescriptionListItem = ({
	doc,
	selectedPrescription,
	handleSelectPrescription,
	handlePreviewPrescription,
}: Props) => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	const handleSelect = () => {
		handleSelectPrescription({ id: doc.id, fileName: doc.fileName });
	};

	const handlePreview = () => {
		handlePreviewPrescription(doc);
	};

	return (
		<ListItem
			key={doc.id}
			sx={{
				height: '80px',
				display: 'flex',
				backgroundColor: theme => theme.palette.grey6.main,
				px: '20px',
				py: {
					xl: '12px',
					lg: '12px',
					md: '14px',
				},
			}}
		>
			<Stack direction={'row'} gap={'20px'}>
				<Radio
					sx={{
						mr: 0,
					}}
					name="existingPrescription"
					disableRipple
					checked={selectedPrescription?.id === doc.id}
					onClick={handleSelect}
					inputProps={{
						'aria-label': `${tClientLabels(
							'newClaim.upload.doc.selectPrescription',
						)}`,
					}}
				/>
				<Stack gap={'15px'}>
					<Typography variant="bodyLink" sx={{ textDecoration: 'underline' }}>
						{doc.fileName}
					</Typography>
					<Typography variant="caption">
						{tClientLabels('newClaim.upload.doc.caption', {
							docDate: getShortDate(doc.creationDate),
							docSize: bytesToKilobytes(Number(doc.size))[0],
							docSizeUnit: bytesToKilobytes(Number(doc.size))[1],
						})}
					</Typography>
				</Stack>
			</Stack>
			<Box ml="auto">
				<IconButton
					onClick={handlePreview}
					disableRipple
					aria-label={tClientLabels('newClaim.upload.doc.showPreview')}
				>
					<VisibileIcon height={'24px'} width={'24px'} />
				</IconButton>
			</Box>
		</ListItem>
	);
};

export default PrescriptionListItem;
