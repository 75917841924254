import { Button, ButtonProps } from '@mui/material';
import Typography from 'styles/typography/Typograpy';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useDevice from 'hooks/DesktopDevice.hook';

type Props = ButtonProps & {
	label: string;
	to: string;
};

const StyledButton = styled(Button)``;

const HomeButton: React.FC<Props> = props => {
	const navigate = useNavigate();
	const { isDesktop } = useDevice();

	return (
		<StyledButton
			{...props}
			disableElevation
			disableFocusRipple
			disableTouchRipple
			disableRipple
			variant="home"
			type="button"
			onClick={() => navigate(props.to)}
		>
			<Typography variant={!!isDesktop ? 'bodyLink' : 'value1'}>
				{props.label}
			</Typography>
		</StyledButton>
	);
};

export default HomeButton;
