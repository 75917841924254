import { getDocuments } from 'feature/documents/documentsSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { Box, BoxOverflow, Stack } from 'styles';
import { IDocumentStructure } from 'types/Document';
import DocumentRenderer from './DocumentRenderer.component';
import DocumentsList from './DocumentsList.component';

const Documentation = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { documents, expandView } = useSelector(
		(store: RootState) => store.documentation,
	);
	const [selectedDocument, setSelectedDocument] = useState<
		IDocumentStructure | undefined
	>();

	const handleClickSelectDocument = (id: number) => {
		const selectedDoc = documents.find(doc => doc.id === id);
		setSelectedDocument(selectedDoc);
	};

	useEffect(() => {
		dispatch(getDocuments({ showloader: true }));
	}, [dispatch]);

	useEffect(() => {
		if (documents.length > 0) {
			setSelectedDocument(documents[0]);
		}
	}, [documents]);

	return documents?.length > 0 ? (
		<Stack
			mt={{ sm: '26px', lg: '40px' }}
			minHeight={{
				sm: 'calc(100% - 30px)',
				lg: 'calc(100% - 46px)',
			}}
			direction="row"
			gap={'20px'}
			pb={{ sm: '20px', lg: '40px' }}
		>
			{expandView && (
				<Stack>
					<BoxOverflow
						variant="y"
						sx={{
							cursor: 'pointer',
							width: { sm: '267px', lg: '497px' },
						}}
					>
						<DocumentsList
							documents={documents}
							selectedDocId={selectedDocument?.id}
							onSelectDocument={handleClickSelectDocument}
						/>
					</BoxOverflow>
				</Stack>
			)}
			<Box
				width={{
					sm: expandView ? 'calc(100% - 267px - 20px)' : '100%',
					lg: expandView ? 'calc(100% - 497px - 20px)' : '100%',
				}}
			>
				{selectedDocument && (
					<DocumentRenderer selectedDocument={selectedDocument} />
				)}
			</Box>
		</Stack>
	) : null;
};

export default Documentation;
