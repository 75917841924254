import { useTheme, useTranslate } from 'stylesHooks';
import { useSelector } from 'react-redux';
import { Link as LinkRouter, useParams } from 'react-router-dom';
import { RootState } from 'store/store';
import { Stack, Typography } from 'styles';
import { PaymentDetailsType, PaymentPathnames } from 'types/Payment';
import { TRANSLATION_KEYS } from 'utils/constants';

const TabTitles = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const theme = useTheme();

	const { paymentDetails } = useSelector((store: RootState) => store.payment);
	const param = useParams();

	const borderEOP = {
		textTransform: 'uppercase',
		paddingBottom: '8px',
		fontWeight: 700,
		border: 'none',
		borderBottom: `1px solid ${
			paymentDetails.detailType === PaymentDetailsType.EOP
				? theme.palette.primary.main
				: 'transparent'
		}`,
	};

	const borderGROUP = {
		textTransform: 'uppercase',
		paddingBottom: '8px',
		fontWeight: 700,
		border: 'none',
		borderBottom: `1px solid ${
			paymentDetails.detailType === PaymentDetailsType.GROUP_INVOICE
				? theme.palette.primary.main
				: 'transparent'
		}`,
	};

	return (
		<Stack display={'flex'} flexDirection={'row'} gap={theme.spacing(3.75)}>
			<LinkRouter
				style={{
					height: 'auto',
					textDecoration: 'none',
					border: 0,
					width: 'auto',
				}}
				to={`/${PaymentPathnames.VIEW_PAYMENT_HISTORY}/${param.id?.split('|')[0]}|${
					param.id?.split('|')[1]
				}/${PaymentPathnames.EXPLANATION_OF_PAYMENT}`}
			>
				<Typography variant="body" sx={borderEOP}>
					{tClientLabels('payment.labels.explanationOfPayment')}
				</Typography>
			</LinkRouter>
			<LinkRouter
				style={{
					textDecoration: 'none',
				}}
				to={`/${PaymentPathnames.VIEW_PAYMENT_HISTORY}/${param.id?.split('|')[0]}|${
					param.id?.split('|')[1]
				}/${PaymentPathnames.GROUP_INVOICE_ASSESSMENT}`}
			>
				<Typography variant="body" sx={borderGROUP}>
					{tClientLabels('payment.labels.groupInvoiceAssessment')}
				</Typography>
			</LinkRouter>
		</Stack>
	);
};

export default TabTitles;
