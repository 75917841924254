import { useTranslate } from 'stylesHooks';
import { resetMember } from 'feature/members/memberSlice';
import { resetMembers, searchMembers } from 'feature/members/membersSlice';
import { usePrevious } from 'hooks/previous.hook';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import Box from 'styles/box/Box';
import Button from 'styles/button/Button';
import Stack from 'styles/stack/Stack';
import TextField from 'styles/textfield/TextField';
import palette from 'styles/theme/Palette';
import { TRANSLATION_KEYS } from 'utils/constants';
import { Typography } from '@mui/material';

const FormFiscalCode: FC = () => {
	const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
	const [value, setValue] = useState<string>('');
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { dateOfService } = useSelector((store: RootState) => store.form);
	const { members } = useSelector((store: RootState) => store.members);
	const previousFiscalCode = usePrevious(value);

	const showCta = useMemo(
		() => !members?.hits || members?.hits?.length === 0,
		[members?.hits],
	);

	const handleOnSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (!dateOfService) return;
		const target = e.target as typeof e.target & {
			inputFiscalCode: { value: string };
		};
		dispatch(
			searchMembers({
				values: {
					size: 10,
					date_of_service: dateOfService,
					fiscal_code: target.inputFiscalCode.value,
				},
			}),
		);
	};

	useEffect(() => {
		if (value?.length > 0 && !!dateOfService) {
			setDisabledSubmit(false);
		} else {
			setDisabledSubmit(true);
		}
	}, [value, dateOfService]);

	useEffect(() => {
		if (
			members?.hits?.length &&
			members?.hits?.length > 0 &&
			previousFiscalCode !== value
		) {
			dispatch(resetMembers());
			dispatch(resetMember());
		}
	}, [dispatch, members?.hits?.length, previousFiscalCode, value]);

	return (
		<Box
			component={'form'}
			onSubmit={handleOnSubmit}
			onChange={() => !!dateOfService && !!value && setDisabledSubmit(false)}
		>
			<Stack>
				<Stack
					gap={{
						sm: '10px', lg: '9px' }}
					minHeight={{ sm: '114px', lg: '130px' }}
				>
					<TextField
						name="inputFiscalCode"
						type="text"
						label={tClientLabels('check-and-submit.searchLabels.fiscalCode')}
						error={members?.total?.value === 0}
						sx={{
							borderColor:
								members?.total?.value === 0
									? palette.error.main
									: value.length > 0
									? palette.primary.main
									: 'none',
						}}
						onChange={e => setValue(e.target.value)}
						autoComplete={'off'}
					/>
					{members?.total?.value === 0 && (
						<Typography variant="value1" color={palette.error.main}>
							{tClientLabels('check-and-submit.errors.fiscalCode')}
						</Typography>
					)}
				</Stack>
				{showCta && (
					<Button
						variant="contained"
						type="submit"
						sx={{
							width: { sm: 112, lg: 116 },
							justifyContent: 'center',
						}}
						disabled={disabledSubmit}
					>
						{tClientLabels('commons.buttons.search')}
					</Button>
				)}
			</Stack>
		</Box>
	);
};

export default FormFiscalCode;
