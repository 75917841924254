import { useDispatch, useSelector } from 'react-redux';
import { BoxOverflow, Typography } from 'styles';

import { downloadClaims } from 'feature/claims/claimsSlice';
import { useParams } from 'react-router-dom';
import { AppDispatch, RootState } from 'store/store';
import Button from 'styles/button/Button';
import ButtonLink from 'styles/button/ButtonLink';
import Stack from 'styles/stack/Stack';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';
import { Dashboard_Go } from 'utils/links';
import { ClaimAttachmentsDetails } from './ClaimAttachmentsDetails.component';
import ClaimMemberDetails from './ClaimMemberDetails.component';
import ClaimPaymentDetails from './ClaimPaymentDetails.component';

const ClaimDetails = () => {
	const dispatch = useDispatch<AppDispatch>();
	const param = useParams();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { errorCode, errorMessage } = useSelector(
		(store: RootState) => store.claim,
	);
	const { profile } = useSelector((store: RootState) => store.user);

	const handleClick = (type: string) => {
		const claimId = parseInt(param.id as string);
		dispatch(downloadClaims({ id: claimId as number, type: type }));
	};

	if (errorCode !== 0) {
		return (
			<Typography variant="bodyLink">{`Claim ${param.id} ${errorMessage} for Provider ${profile?.location}`}</Typography>
		);
	}

	return (
		<BoxOverflow
			variant="y"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				justifyContent: 'space-between',
			}}
		>
			<Stack
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: { lg: 7.5, sm: 3.25 },
					width: '100%',
				}}
			>
				<BoxOverflow
					variant="x"
					sx={{
						width: '100%',
					}}
				>
					<ClaimMemberDetails />
				</BoxOverflow>
				<ClaimPaymentDetails />
				<ClaimAttachmentsDetails />
			</Stack>
			<Stack
				direction={'row'}
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					mt: { lg: 7.5, sm: 3.25 },
					alignItems: 'center',
				}}
			>
				<Stack sx={{ alignItems: 'flex-start', paddingBottom: 2, paddingleft: 0 }}>
					{Dashboard_Go(tClientLabels).map(
						(e: {
							id: string;
							icon: React.ReactNode;
							name: string;
							link: string;
						}) => {
							if (e.id === 'dashboard_button_go_to_essilor') {
								return (
									<ButtonLink key={e.id} startIcon={e.icon} label={e.name} to={e.link} />
								);
							} else {
								return '';
							}
						},
					)}
				</Stack>

				<Stack
					direction={'column'}
					sx={{
						gap: 2,
						alignContent: 'center',
						justifyContent: 'flex-end',
						paddingBottom: 2,
					}}
				>
					<Button
						type="button"
						variant="contained"
						fullWidth={false}
						onClick={() => handleClick('PDF')}
					>
						{tClientLabels('claim.button.download')}
					</Button>
				</Stack>
			</Stack>
		</BoxOverflow>
	);
};

export default ClaimDetails;
