import Logo from 'assett/icons/logo.svg';
import SelectLanguage from 'components/commons/form/SelectLanguage';
import UserProvider from 'components/Dahsboard/User/UserProvider.component';
import useDevice from 'hooks/DesktopDevice.hook';
import useDialogConfirm from 'hooks/DialogConfirm.hook';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { RootState } from 'store/store';
import { Box } from 'styles';
import Container from 'styles/container/Container';
import Stack from 'styles/stack/Stack';
import Typography from 'styles/typography/Typograpy';
import { useTranslate } from 'stylesHooks';
import { VERSION } from 'types/dialogtypes';
import { FooterDesktop } from './FooterDesktop/FooterDesktop';

const DashboardLayout = () => {
	const { t: tClientLabels } = useTranslate('clientLabels');
	const { profile } = useSelector((store: RootState) => store.user);
	const { isDesktop } = useDevice();
	const confirmDialog = useDialogConfirm();

	return (
		<Container>
			<Stack
				justifyContent="space-between"
				height={!isDesktop ? `${window.innerHeight}px` : '100vh'}
			>
				<Box>
					<Stack
						sx={{ mt: 6.25 }}
						direction={'row'}
						justifyContent={'space-between'}
					>
						<Stack>
							<Typography variant="title1">
								{tClientLabels('dashboard.pageTitle')}
							</Typography>
							<Typography
								variant={!!isDesktop ? 'title2' : 'title4'}
								sx={{ color: 'primary3.main' }}
							>
								{profile?.name as string}
							</Typography>
						</Stack>

						<Stack alignItems={'flex-end'} sx={{ mt: { sm: 0, lg: 2.5 } }}>
							<Box width="48px" height="49px">
								<img
									onClick={() => {
										confirmDialog.open(VERSION);
									}}
									src={Logo}
									alt="logo"
									style={{
										width: '200%',
										height: '200%',
										transformOrigin: '0 0',
										transform: 'scale(0.5)',
									}}
								/>
							</Box>
						</Stack>
					</Stack>
					<Stack direction={'row'} justifyContent="space-between">
						<Box width={{ sm: '68%', lg: '60%' }}>
							<UserProvider />
						</Box>
						<Box>
							<SelectLanguage />
						</Box>
					</Stack>
				</Box>
				<Stack mb={!!isDesktop ? 6.5 : 2.5}>
					<Outlet />
				</Stack>
				<FooterDesktop />
			</Stack>
		</Container>
	);
};

export default DashboardLayout;
