import { List as ListMui, ListProps as ListPropsMui } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

type ListProps = ListPropsMui;

const StyledList = styled(ListMui)``;

const List: React.FC<ListProps> = props => {
	return <StyledList {...props}>{props.children}</StyledList>;
};

export default List;
