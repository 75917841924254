import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sortingDirections } from 'types/Form';
import { IPaymentsState } from 'types/Payments';
import {
	downloadPaymentClaimThunk,
	downloadPaymentGroupThunk,
	downloadPaymentThunk,
} from './paymentThunk';
import { getPaymentsHistoryByProviderThunk } from './paymentsThunk';

const initialState: IPaymentsState = {
	errorCode: 0,
	errorMessage: '',
	payments: [],
	change: false,
	paymentsIds: [],
	paymentExcludeIds: [],
	downloadAll: false,
	pages: 1,
	page: 1,
	search: false,
	sortingFields: 'paymentRunDate',
	sortingDirections: sortingDirections.DESC,
};

export const getPaymentsHistory = createAsyncThunk(
	'payments/paymentsHistory',
	getPaymentsHistoryByProviderThunk,
);

export const downloadPaymentClaim = createAsyncThunk(
	'payment/downloadPaymentClaim',
	downloadPaymentClaimThunk,
);

export const downloadPaymentGroup = createAsyncThunk(
	'payment/downloadPaymentGroup',
	downloadPaymentGroupThunk,
);

export const downloadPayments = createAsyncThunk(
	'payment/downloadPayments',
	downloadPaymentThunk,
);

const paymentsSlice = createSlice({
	name: 'payments',
	initialState,
	reducers: {
		changePage: (state: IPaymentsState, { payload }: PayloadAction<number>) => {
			state.page = payload;
		},
		setSearch: (state: IPaymentsState, { payload }: PayloadAction<boolean>) => {
			state.search = payload;
		},
		setPaymentsIds: (state, { payload }: PayloadAction<string[] | []>) => {
			if (payload.length > 0) {
				state.paymentsIds = state.paymentsIds
					.concat(payload)
					.filter(
						(value: string, index: number, self: string[]) =>
							self.indexOf(value) === index,
					);
			} else {
				state.paymentsIds = [];
			}
		},
		setPaymentExcludeIds: (state, { payload }: PayloadAction<string[] | []>) => {
			state.paymentExcludeIds = payload;
		},
		setDownloadAll: (
			state: IPaymentsState,
			{ payload }: PayloadAction<boolean>,
		) => {
			state.downloadAll = payload;
		},
		setSelectOne: (state, { payload }: PayloadAction<string>) => {
			if (state.paymentsIds.includes(payload)) {
				const list = state.paymentsIds.filter((e: string) => e !== payload);
				state.paymentsIds = list;
				if (state.downloadAll) {
					state.paymentExcludeIds.push(payload);
				}
			} else {
				state.paymentsIds.push(payload);
				state.paymentExcludeIds = state.paymentExcludeIds.filter(
					(id: string) => id !== payload,
				);
			}
		},

		resetPayments: () => initialState,
	},
	extraReducers: builder => {
		builder
			.addCase(getPaymentsHistory.fulfilled, (state, { payload }) => {
				state.errorCode = 0;
				state.errorMessage = '';
				state.payments = payload.paymentList;
				state.change = true;
				state.pages = payload.pages;
			})
			.addCase(getPaymentsHistory.rejected, (state, { payload }: any) => {
				state.errorCode = payload.errorCode;
				state.errorMessage = payload.errorMessage;
				state.payments = [];
			})
			.addCase(downloadPaymentClaim.fulfilled, state => {
				state.errorCode = 0;
				state.errorMessage = '';
			})
			.addCase(downloadPaymentClaim.rejected, (state, { payload }: any) => {
				state.errorCode = payload.status;
				state.errorMessage = payload.statusText;
			})
			.addCase(downloadPaymentGroup.fulfilled, state => {
				state.errorCode = 0;
				state.errorMessage = '';
			})
			.addCase(downloadPaymentGroup.rejected, (state, { payload }: any) => {
				state.errorCode = payload.status;
				state.errorMessage = payload.statusText;
			})
			.addCase(downloadPayments.fulfilled, state => {
				state.errorCode = 0;
				state.errorMessage = '';
			})
			.addCase(downloadPayments.rejected, (state, { payload }: any) => {
				state.errorCode = payload.status;
				state.errorMessage = payload.statusText;
			});
	},
});

export const {
	resetPayments,
	setSearch,
	changePage,
	setDownloadAll,
	setPaymentsIds,
	setPaymentExcludeIds,
	setSelectOne,
} = paymentsSlice.actions;
export default paymentsSlice.reducer;
