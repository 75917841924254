import { ThunkApiType } from 'types/storeTypes';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { Eye, IClaim, IPrescription } from 'types/Claim';
import { prescriptionColumnsConfiguration } from 'utils/prescription.hook';

export const getClaimDetailsThunk = async (
	claimid: string,
	thunkAPI: ThunkApiType,
) => {
	let getClaimDetailsURL = `/claim/${claimid}`;

	try {
		const response = await authFetch.get(getClaimDetailsURL);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getPrescriptionsThunk = async (
	_: object,
	thunkAPI: ThunkApiType,
) => {
	const { memberSubscriptionId } = thunkAPI.getState().claim.claimData;
	try {
		const response = await authFetch.get(
			`/prescription?subscriptionId=${memberSubscriptionId}`,
		);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const saveClaimThunk = async (
	input: { switchPage: boolean; redirect: boolean },
	thunkAPI: ThunkApiType,
) => {
	let formData = thunkAPI.getState().claim.claimData;
	if (input.switchPage) {
		formData = { ...formData, step: formData.step ? formData.step + 1 : 1 };
	}

	formData = {
		...formData,
		newPrescriptionContent: formData.newPrescriptionContent
			? fixPrescription(formData.newPrescriptionContent, 'BE')
			: undefined,
		oldPrescriptionContent: formData.oldPrescriptionContent
			? fixPrescription(formData.oldPrescriptionContent, 'BE')
			: undefined,
	};

	try {
		const response = await authFetch.post('/claim', formData);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const calculateEstimatesThunk = async (
	formData: IClaim,
	thunkAPI: ThunkApiType,
) => {
	formData = {
		...formData,
		newPrescriptionContent: formData.newPrescriptionContent
			? fixPrescription(formData.newPrescriptionContent, 'BE')
			: undefined,
		oldPrescriptionContent: formData.oldPrescriptionContent
			? fixPrescription(formData.oldPrescriptionContent, 'BE')
			: undefined,
	};

	try {
		const response = await authFetch.post('/claim/calculateCost', formData);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const submitClaimThunk = async (
	formData: IClaim,
	thunkAPI: ThunkApiType,
) => {
	formData = {
		...formData,
		newPrescriptionContent: formData.newPrescriptionContent
			? fixPrescription(formData.newPrescriptionContent, 'BE')
			: undefined,
		oldPrescriptionContent: formData.oldPrescriptionContent
			? fixPrescription(formData.oldPrescriptionContent, 'BE')
			: undefined,
	};

	try {
		const response = await authFetch.post('/claim/submit', formData);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getDoctorTypesThunk = async (
	_: string,
	thunkAPI: ThunkApiType,
) => {
	try {
		const response = await authFetch.get('/doctor/typologies');
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const fixPrescription = (
	prescription: IPrescription,
	destination: 'FE' | 'BE',
) => {
	const columns = prescriptionColumnsConfiguration({
		prescription,
		checkEmptyFields: false,
		isOldPrescription: false,
		isSingleVision: true,
	});

	const formatterToPick =
		destination === 'FE' ? 'formatValueVideo' : 'formatValueBackend';

	const newPrescription = {
		...prescription,
		os: { ...prescription.os },
		od: { ...prescription.od },
	};

	(['od', 'os'] as Eye[]).forEach(eye =>
		columns.forEach(col => {
			newPrescription[eye][col.property] = (
				col[formatterToPick]
					? col[formatterToPick]!(prescription[eye][col.property] as string)
					: prescription[eye][col.property]
			) as string;
		}),
	);
	return newPrescription;
};
