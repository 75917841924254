import useDevice from 'hooks/DesktopDevice.hook';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { RootState } from 'store/store';
import { BoxOverflow, Grid, Typography } from 'styles';
import Box from 'styles/box/Box';
import Stack from 'styles/stack/Stack';
import { PaymentDetailsType, PaymentPathnames } from 'types/Payment';
import AdditionalDetails from './PaymentDetailsSections/AdditionalDetails.component';
import ClaimsTable from './PaymentDetailsSections/ClaimsTable.component';
import DownloadButton from './PaymentDetailsSections/DownloadButton.component';
import PaymentFrom from './PaymentDetailsSections/PaymentFrom.component';
import PaymentTo from './PaymentDetailsSections/PaymentTo.component';
import TabTitles from './PaymentDetailsSections/TabTitles.component';
import Ticket from './PaymentDetailsSections/Ticket.component';

const PaymentDetails = () => {
	const param = useParams();
	const location = useLocation();
	const { isDesktop } = useDevice();

	const { errorCode, errorMessage, paymentDetails } = useSelector(
		(store: RootState) => store.payment,
	);
	const { profile } = useSelector((store: RootState) => store.user);
	const splittedLocation = location.pathname.split('/');

	if (errorCode !== 0) {
		return (
			<Typography variant="bodyLink">{`Payment ${param.id} ${errorMessage} for provider ${profile?.location}`}</Typography>
		);
	}

	const checkTabVisibility =
		paymentDetails.detailType !== PaymentDetailsType.ASSESSMENT_FEE &&
		paymentDetails.detailType !== '';

	return (
		<Stack height={'100%'}>
			<BoxOverflow variant="y">
				<Stack direction="column" justifyContent={'space-between'} pr={1}>
					<Box
						display={'flex'}
						flexDirection={'column'}
						flexWrap={'wrap'}
						justifyContent={'space-between'}
					>
						{checkTabVisibility && (
							<Stack>
								<TabTitles />
							</Stack>
						)}

						<Grid
							container
							columnSpacing={isDesktop ? 5 : 2.5}
							rowGap={isDesktop ? 7.5 : 5}
							mt={5}
						>
							<Grid item lg={6} md={6} sm={6} pt={0}>
								<PaymentFrom />
							</Grid>
							<Grid item lg={6} md={6} sm={6}>
								<PaymentTo />
							</Grid>
							<Grid item lg={6} md={6} sm={6}>
								<AdditionalDetails />
							</Grid>
							<Grid item lg={6} md={6} sm={6}>
								<Ticket />
							</Grid>
							<Grid item lg={12} md={12} sm={12}>
								<ClaimsTable />
							</Grid>
						</Grid>
					</Box>
				</Stack>
			</BoxOverflow>
			<Stack
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'flex-end'}
				pt={1}
				pb={isDesktop ? 5 : 2.5}
			>
				<DownloadButton
					type={
						splittedLocation.includes(PaymentPathnames.ASSESSMENT_FEE_INVOICE)
							? 'claim'
							: 'group'
					}
				/>
			</Stack>
		</Stack>
	);
};

export default PaymentDetails;
