import { useTheme, useTranslate } from 'stylesHooks';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Box, Stack, Typography } from 'styles';
import { PaymentDetailsType } from 'types/Payment';
import { TRANSLATION_KEYS } from 'utils/constants';
import { getShortDate } from 'utils/utils';

const AdditionalDetails = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { t: tDbLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	const theme = useTheme();
	const { paymentDetails } = useSelector((store: RootState) => store.payment);

	const stackGap =
		paymentDetails.detailType === PaymentDetailsType.EOP
			? theme.spacing(2.125)
			: theme.spacing(2.55);

	return (
		<>
			<Stack
				display={'flex'}
				alignItems={'flex-start'}
				flex={'1 0 0'}
				gap={stackGap}
				pt={3}
				pb={2.5}
				px={2.5}
				borderRadius={1}
				bgcolor={theme.palette.white.main}
			>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					alignSelf={'stretch'}
				>
					<Typography variant="body">
						{tClientLabels('payment.details.dateOfInvoice')}:
					</Typography>
					<Typography variant="bodyLink">
						{getShortDate(paymentDetails.paymentInfo.invoiceDate)}
					</Typography>
				</Box>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					alignSelf={'stretch'}
				>
					<Typography variant="body">
						{tClientLabels('payment.details.idNumber')}:
					</Typography>
					<Typography variant="bodyLink">
						{paymentDetails.paymentInfo.invoiceNumber}
					</Typography>
				</Box>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					alignSelf={'stretch'}
				>
					<Typography variant="body">
						{tClientLabels('payment.details.typeOfService')}:
					</Typography>
					<Typography variant="bodyLink">
						{tDbLabels(paymentDetails.paymentInfo.typeOfService)}
					</Typography>
				</Box>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					alignSelf={'stretch'}
				>
					<Typography variant="body" width={'40%'}>
						{tClientLabels('payment.details.paymentTerms')}:
					</Typography>
					<Typography variant="bodyLink" textAlign={'right'}>
						{tDbLabels(paymentDetails.paymentInfo.paymentTerms)}
					</Typography>
				</Box>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					alignSelf={'stretch'}
				>
					<Typography variant="body">
						{tClientLabels('payment.details.service')}:
					</Typography>
					<Typography variant="bodyLink">
						{tDbLabels(paymentDetails.paymentInfo.service)}
					</Typography>
				</Box>
				{paymentDetails.detailType !== PaymentDetailsType.EOP && (
					<Box
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
						alignSelf={'stretch'}
					>
						<Typography variant="body">
							{tClientLabels('payment.details.quantity')}:
						</Typography>
						<Typography variant="bodyLink">
							{paymentDetails.paymentInfo.quantity}
						</Typography>
					</Box>
				)}
			</Stack>
		</>
	);
};

export default AdditionalDetails;
