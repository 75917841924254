import { getClaimDetails, resetClaim } from 'feature/claims/claimSlice';

import ClaimDetails from 'components/ViewClaimDetails/ClaimDetails.component';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from 'store/store';

const ViewClaimDetails = () => {
	const param = useParams();
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(resetClaim());
		dispatch(getClaimDetails(param.id as string));
	}, [dispatch, param.id]);

	return <ClaimDetails />;
};

export default ViewClaimDetails;
