import { Box100, Box130 } from 'components/PaymentDetails/boxes';
import useDevice from 'hooks/DesktopDevice.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Box, Stack, Typography } from 'styles';
import { useTheme, useTranslate } from 'stylesHooks';
import { PaymentDetailsType } from 'types/Payment';
import { TRANSLATION_KEYS } from 'utils/constants';
import { formatPrice, getShortDate } from 'utils/utils';
import TableFooter from './TableFooter.component';

const TableBody = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const theme = useTheme();
	const { isMobile } = useDevice();

	const { paymentDetails } = useSelector((store: RootState) => store.payment);

	if (paymentDetails.paymentClaims.length === 0) {
		return (
			<Typography variant="bodyLink">
				{tClientLabels('payment.labels.noClaimFound')}
			</Typography>
		);
	}

	return (
		<Box
			display={'inline-block'}
			width={isMobile ? 'auto' : '100%'}
			bgcolor={theme.palette.white.main}
			borderRadius={1}
			pt={1.25}
			px={2}
			pb={1.5}
		>
			{paymentDetails.paymentClaims.map(claim => (
				<Stack
					key={claim.claimId}
					display={'flex'}
					flexDirection={'row'}
					justifyContent={'space-between'}
					alignItems={'flex-start'}
					alignSelf={'stretch'}
					gap={theme.spacing(1.25)}
				>
					<Box100 mt={1.25} sx={{ textAlign: 'initial !important' }}>
						<Typography variant="body">{claim.externalClaimId}</Typography>
					</Box100>
					<Box100 sx={{ textAlign: 'center' }} mt={1.25}>
						<Typography variant="body">
							{getShortDate(claim.dateOfService)}
						</Typography>
					</Box100>
					<Box100 mt={1.25}>
						<Typography variant="body">{formatPrice(claim.totalCharges)}</Typography>
					</Box100>
					<Box100 mt={1.25}>
						<Typography variant="body">
							{formatPrice(claim.contractualWriteOff)}
						</Typography>
					</Box100>
					<Box100 mt={1.25}>
						<Typography variant="body">
							{formatPrice(claim.memberResponsible)}
						</Typography>
					</Box100>
					<Box100 mt={1.25}>
						<Typography variant="body">{formatPrice(claim.claimPayment)}</Typography>
					</Box100>
					<Box100 mt={1.25}>
						<Typography variant="body">{formatPrice(claim.assessmentFee)}</Typography>
					</Box100>
					<Box100 mt={1.25}>
						<Typography variant="body">
							{formatPrice(claim.assessmentFeeTax)}
						</Typography>
					</Box100>
					<Box100 mt={1.25}>
						<Typography variant="body">{formatPrice(claim.other)}</Typography>
					</Box100>
					{paymentDetails.detailType === PaymentDetailsType.EOP && (
						<Box100 mt={1.25}>
							<Typography variant="body">{formatPrice(claim.netDue)}</Typography>
						</Box100>
					)}
					<Box130 mt={1.25}>
						<Typography variant="body">{claim.location}</Typography>
					</Box130>
				</Stack>
			))}
			<TableFooter />
		</Box>
	);
};

export default TableBody;
