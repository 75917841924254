import useDevice from 'hooks/DesktopDevice.hook';
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';
import { Button, Stack, Typography } from 'styles';
import { useTranslate } from 'stylesHooks';
import { Eye, IEyePrescription, IPrescription } from 'types/Claim';
import { TRANSLATION_KEYS } from 'utils/constants';
import {
	columnProperties,
	useColumnConfiguration,
} from 'utils/prescription.hook';
import PrescriptionField from './PrescriptionField';

type prescriptionProps = {
	data: IPrescription;
	setData?: (arg0: IPrescription) => void;
	editable?: boolean;
	checkFields: boolean;
	setRequiredFilled: Dispatch<SetStateAction<boolean>>;
	setErrorPresent: Dispatch<SetStateAction<boolean>>;
	presFilled: boolean;
	isOldPrescription: boolean;
	isContactOnly: boolean;
	isSingleVision: boolean;
};

const COLUMNS_NUMBER_TABLET = 8;

const PrescriptionTable: FC<prescriptionProps> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { isDesktop } = useDevice();
	const {
		data,
		setData = () => {},
		editable = true,
		checkFields,
		setRequiredFilled,
		setErrorPresent,
		isOldPrescription,
		isContactOnly,
		isSingleVision,
	} = props;

	const {
		columns: prescriptionColumnsOnlyErrors,
		columnsErrors: columnsOnlyErrors,
	} = useColumnConfiguration(
		data,
		false, //checkFields
		isOldPrescription,
		isContactOnly,
		isSingleVision,
	);

	//check if the prescription is filled with all required fields. the previous hook only checks for current errors
	const {
		columns: prescriptionColumnsRequired,
		columnsErrors: columnsRequired,
	} = useColumnConfiguration(
		data,
		true, //checkFields
		isOldPrescription,
		isContactOnly,
		isSingleVision,
	);

	const columnsErrors = checkFields ? columnsRequired : columnsOnlyErrors;
	const prescriptionColumns = checkFields
		? prescriptionColumnsRequired
		: prescriptionColumnsOnlyErrors;

	const checkRequired = useCallback(() => {
		setRequiredFilled(
			columnsRequired.od.findIndex(el => el) === -1 &&
				columnsRequired.os.findIndex(el => el) === -1,
		);
	}, [columnsRequired.od, columnsRequired.os, setRequiredFilled]);

	const checkErrors = useCallback(() => {
		setErrorPresent(
			columnsOnlyErrors.od.findIndex(el => el) !== -1 ||
				columnsOnlyErrors.os.findIndex(el => el) !== -1,
		);
	}, [setErrorPresent, columnsOnlyErrors.od, columnsOnlyErrors.os]);

	useEffect(() => {
		checkRequired();
	}, [checkRequired]);

	useEffect(() => {
		checkErrors();
	}, [checkErrors]);

	const prescriptionColumnsSplitted = isDesktop
		? [prescriptionColumns]
		: prescriptionColumns.reduce(
				(sum, el) => {
					if (sum[sum.length - 1].length < COLUMNS_NUMBER_TABLET) {
						sum[sum.length - 1].push(el);
					} else {
						sum.push([el]);
					}
					return sum;
				},
				[[]] as columnProperties[][],
		  );

	const handlerDisabledBtn = (data: IEyePrescription, index: number) => {
		let isBtnDisabled = false;

		const arrayFields = Object.keys(data);

		for (let field of arrayFields) {
			const key = field as keyof IEyePrescription;

			if (
				prescriptionColumnsSplitted[index].map(el => el.property).includes(key)
			) {
				if (data[key]) {
					isBtnDisabled = false;
					break;
				} else isBtnDisabled = true;
			}
		}
		return isBtnDisabled;
	};

	const copyRow = (args: {
		data: IPrescription;
		columns: typeof prescriptionColumns;
		from: Eye;
		to: Eye;
	}) => {
		const { data, columns, from, to } = args;
		const toNewValue: IEyePrescription = {};
		columns.forEach(({ property }) => {
			toNewValue[property] = data[from][property];
		});

		const newData = { ...data, [to]: { ...data[to], ...toNewValue } };
		setData(newData);
	};

	const handleChange = (formattedValue: string, property: string, eye: Eye) => {
		const newData = {
			...data,
			[eye]: {
				...data[eye],
				[property]: formattedValue,
			},
		};
		setData(newData);
	};

	return (
		<>
			<Stack gap={3.75}>
				{prescriptionColumnsSplitted.map((cols, indexSplit) => (
					<Stack key={indexSplit} gap={2}>
						<Stack direction="row" gap={1} alignItems={'end'}>
							{/* PLACEHOLDER FOR EMPTY COLUMN */}
							<Typography variant="caption" width="37px" height={'100%'} />
							{cols.map(col => (
								<Typography
									key={`title-${col.title}`}
									variant="caption"
									width={'70px'}
									height={'100%'}
									sx={{ overflowWrap: 'break-word' }}
								>
									{col.title &&
										tClientLabels(`newClaim.prescription.eyesPrescriton.${col.title}`)}
									{
										col.required ? '' : '' //always empty in case we need to add it back again
									}
								</Typography>
							))}
						</Stack>
						{[Eye.od, Eye.os].map(eye => (
							<Stack
								key={`.prescriptionRow.${eye.toUpperCase()}`}
								direction="row"
								alignItems="center"
								justifyContent={{ sm: 'space-between', lg: 'normal' }}
							>
								<Stack direction="row" alignItems="center">
									<Typography variant="value1" width="45px" height={'100%'}>
										{tClientLabels(
											`newClaim.prescription.eyesPrescriton.${eye.toUpperCase()}`,
										)}
									</Typography>

									<Stack direction="row" gap={1} alignItems="center">
										{cols.map((col, colIndex) => (
											<PrescriptionField
												key={`${eye}-${col.property}`}
												type={col.type}
												disabled={!editable}
												value={data[eye][col.property] || ''}
												onChange={newValue =>
													handleChange(newValue.currentTarget.value || '', col.property, eye)
												}
												onBlur={newValue =>
													handleChange(
														col.formatValueVideo
															? col.formatValueVideo(newValue.currentTarget.value)
															: newValue.currentTarget.value || '',
														col.property,
														eye,
													)
												}
												options={col.options}
												error={
													!!columnsErrors[eye][indexSplit * COLUMNS_NUMBER_TABLET + colIndex]
												}
												tooltip={
													columnsErrors[eye][indexSplit * COLUMNS_NUMBER_TABLET + colIndex]
												}
											/>
										))}
									</Stack>
								</Stack>
								<Stack width="176px">
									<Button
										variant="contained"
										onClick={() => {
											copyRow({
												data,
												columns: cols,
												from: eye,
												to: eye === Eye.od ? Eye.os : Eye.od,
											});
										}}
										sx={{ ml: 3, width: 'max-content' }}
										disabled={!editable || handlerDisabledBtn(data[eye], indexSplit)}
									>
										{tClientLabels(
											`newClaim.prescription.eyesPrescriton.copyTo${(eye === Eye.od
												? Eye.os
												: Eye.od
											).toUpperCase()}`,
										)}
									</Button>
								</Stack>
							</Stack>
						))}
					</Stack>
				))}
			</Stack>
		</>
	);
};

export default PrescriptionTable;
