import { useTranslate } from 'stylesHooks';
import { handleChange, resetInputMemberId } from 'feature/form/formSlice';
import { resetMember } from 'feature/members/memberSlice';
import { resetMembers, searchMembers } from 'feature/members/membersSlice';
import { usePrevious } from 'hooks/previous.hook';
import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Box from 'styles/box/Box';
import Button from 'styles/button/Button';
import Stack from 'styles/stack/Stack';
import TextField from 'styles/textfield/TextField';
import palette from 'styles/theme/Palette';
import { TRANSLATION_KEYS } from 'utils/constants';
import { Typography } from 'styles';

const FormMemberID: FC = () => {
	const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
	const dispatch = useDispatch<any>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { dateOfService, inputMemberId } = useSelector(
		(store: RootState) => store.form,
	);
	const { members } = useSelector((store: RootState) => store.members);
	const previousMemberID = usePrevious(inputMemberId);

	const showCta = useMemo(
		() => !members?.hits || members?.hits?.length === 0,
		[members?.hits],
	);

	const handleOnSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		if (!dateOfService) return;
		const target = e.target as typeof e.target & {
			inputMemberId: { name: string; value: string };
		};
		dispatch(
			searchMembers({
				values: {
					size: 10,
					date_of_service: dateOfService,
					member_id: target.inputMemberId.value,
				},
			}),
		);
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		dispatch(
			handleChange<any>({
				name: e.target.name,
				value: e.target.value,
			}),
		);
	};

	useEffect(() => {
		if (inputMemberId?.length > 0 && dateOfService) {
			setDisabledSubmit(false);
		} else {
			setDisabledSubmit(true);
		}
	}, [inputMemberId, dateOfService]);

	useEffect(() => {
		if (
			members?.hits?.length &&
			members?.hits?.length > 0 &&
			previousMemberID !== inputMemberId
		) {
			dispatch(resetMembers());
			dispatch(resetMember());
		}
	}, [dispatch, members?.hits?.length, previousMemberID, inputMemberId]);

	useEffect(() => {
		return () => dispatch(resetInputMemberId());
	}, [dispatch]);

	return (
		<Box
			component={'form'}
			onSubmit={handleOnSubmit}
			onChange={() =>
				!!dateOfService && !!inputMemberId && setDisabledSubmit(false)
			}
		>
			<Stack>
				<Stack
					gap={{ sm: '10px', lg: '9px' }}
					minHeight={{ sm: '114px', lg: '130px' }}
				>
					<TextField
						autoComplete={'off'}
						name="inputMemberId"
						type="text"
						label={tClientLabels('check-and-submit.searchLabels.memberID')}
						error={members?.total?.value === 0}
						sx={{
							borderColor:
								members?.total?.value === 0
									? palette.error.main
									: inputMemberId.length > 0
									? palette.primary.main
									: 'none',
						}}
						onChange={e => handleInputChange(e)}
						value={inputMemberId}
					/>
					{members?.total?.value === 0 && (
						<Typography variant="value1" color={palette.error.main}>
							{tClientLabels('check-and-submit.errors.fiscalCode')}
						</Typography>
					)}
				</Stack>
				{showCta && (
					<Button
						variant="contained"
						type="submit"
						sx={{
							width: { sm: 112, lg: 116 },
							justifyContent: 'center',
						}}
						disabled={disabledSubmit}
					>
						{tClientLabels('commons.buttons.search')}
					</Button>
				)}
			</Stack>
		</Box>
	);
};

export default FormMemberID;
