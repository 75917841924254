import { useTheme, useTranslate } from 'stylesHooks';
import useDevice from 'hooks/DesktopDevice.hook';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { RootState } from 'store/store';
import { Box, BoxOverflow, Button, Stack, Typography } from 'styles';
import { PaymentPathnames } from 'types/Payment';
import { TRANSLATION_KEYS } from 'utils/constants';
import { formatDateWithoutZulu, formatPrice, getShortDate } from 'utils/utils';
import PaymentClaimsFooter from './PaymentClaims/PaymentClaimsFooter.component';
import PaymentClaimsHeader from './PaymentClaims/PaymentClaimsHeader.component';
import PaymentClaimsList from './PaymentClaims/PaymentClaimsList.component';

const TYPOGRAPHY_WIDTH = 37.5;
const TYPOGRAPHY_GAP = 1.25;

const Payment = () => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const param = useParams();
	const theme = useTheme();
	const { isDesktop } = useDevice();

	const { errorCode, errorMessage, paymentRun } = useSelector(
		(store: RootState) => store.payment,
	);
	const { profile } = useSelector((store: RootState) => store.user);

	if (errorCode !== 0) {
		return (
			<Typography variant="bodyLink">{`Payment ${param.id} ${errorMessage} for provider ${profile?.location}`}</Typography>
		);
	}

	const headerTypographySX = { width: theme.spacing(TYPOGRAPHY_WIDTH) };
	const headerBoxSX = {
		display: 'flex',
		alignItems: 'center',
		width: theme.spacing(TYPOGRAPHY_WIDTH),
		gap: isDesktop ? 0 : theme.spacing(TYPOGRAPHY_GAP),
	};

	return (
		<>
			<BoxOverflow
				variant="y"
				sx={{
					marginTop: isDesktop ? 7.125 : 0,
				}}
			>
				<Box>
					<Stack
						display={'flex'}
						flexDirection={'row'}
						justifyItems={'start'}
						alignItems={'center'}
						alignSelf={'stretch'}
						pl={1.25}
						py={1}
						pr={2.5}
						bgcolor={theme.palette.grey5.main}
						borderRadius={'4px 4px 0px 0px'}
					>
						<Typography sx={headerTypographySX} variant="bodyLink">
							{tClientLabels('payment.labels.paymentRunDate')}
						</Typography>
						<Typography sx={headerTypographySX} variant="bodyLink">
							{tClientLabels('payment.labels.paymentRunId')}
						</Typography>
						<Typography sx={headerTypographySX} variant="bodyLink">
							{tClientLabels('payment.labels.totalAmount')}
						</Typography>
						<Typography sx={headerTypographySX} variant="bodyLink">
							{tClientLabels('payment.labels.explanationOfPayment')}
						</Typography>
						<Typography sx={headerTypographySX} variant="bodyLink">
							{tClientLabels('payment.labels.groupInvoiceAssessment')}
						</Typography>
					</Stack>
					<Stack
						display={'flex'}
						flexDirection={'row'}
						justifyContent={'start'}
						alignItems={'center'}
						alignSelf={'stretch'}
						pl={1.25}
						py={1}
						pr={2.5}
						bgcolor={theme.palette.white.main}
						borderRadius={'0px 0px 4px 4px'}
						height={theme.spacing(7)}
						mt={0.25}
					>
						<Box sx={headerBoxSX}>
							<Typography variant="body">
								{getShortDate(paymentRun.paymentRunDate)}
							</Typography>
						</Box>
						<Box sx={headerBoxSX}>
							<Typography variant="body">#{paymentRun.paymentRunId}</Typography>
						</Box>
						<Box sx={headerBoxSX}>
							<Typography variant="body">
								{formatPrice(paymentRun.totalAmount)}
							</Typography>
						</Box>
						<Box sx={headerBoxSX}>
							<Link
								style={{
									textDecoration: 'none',
								}}
								to={`/${PaymentPathnames.VIEW_PAYMENT_HISTORY}/${
									paymentRun.paymentRunId
								}|${formatDateWithoutZulu(paymentRun.paymentRunDate)}/${
									PaymentPathnames.EXPLANATION_OF_PAYMENT
								}`}
							>
								<Button variant="linkDark">
									{tClientLabels('payment.labels.viewDetail')}
								</Button>
							</Link>
						</Box>
						<Box sx={headerBoxSX}>
							<Link
								style={{
									textDecoration: 'none',
								}}
								to={`/${PaymentPathnames.VIEW_PAYMENT_HISTORY}/${
									paymentRun.paymentRunId
								}|${formatDateWithoutZulu(paymentRun.paymentRunDate)}/${
									PaymentPathnames.GROUP_INVOICE_ASSESSMENT
								}`}
							>
								<Button variant="linkDark">
									{tClientLabels('payment.labels.viewDetail')}
								</Button>
							</Link>
						</Box>
					</Stack>
					<Box mt={5} mb={3.125}>
						<Typography variant="title5">
							{tClientLabels('payment.labels.relatedClaims')}
						</Typography>
					</Box>
					<>
						<PaymentClaimsHeader />
						<PaymentClaimsList />
					</>
				</Box>
			</BoxOverflow>
			<PaymentClaimsFooter />
		</>
	);
};

export default Payment;
