import useDevice from 'hooks/DesktopDevice.hook';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import Paper from 'styles/paper/paper';
import Stack from 'styles/stack/Stack';
import CompositeTypography from 'styles/typography/CompositeTypography.styles';
import { useTranslate } from 'stylesHooks';
import { TRANSLATION_KEYS } from 'utils/constants';
import ClaimStatusBreadcrumbs from './ClaimStatusBreadcrumbs.component';

const ClaimMemberDetails = () => {
	const { isDesktop } = useDevice();
	const { claimData: claim } = useSelector((store: RootState) => store.claim);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { t: tDbLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2.5,
				justifyContent: 'space-between',
				display: 'flex',
				borderRadius: 0.5,
				width: '100%',
			}}
		>
			<Stack flexGrow={1} rowGap={1.875}>
				<CompositeTypography
					label={tClientLabels('claim.labels.memberFullName')}
					value={claim?.memberFullName as string}
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
				/>
				<CompositeTypography
					label={tClientLabels('claim.labels.memberId')}
					value={claim?.memberId as string}
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
				/>
				<CompositeTypography
					label={tClientLabels('claim.labels.memberDesignation')}
					value={tDbLabels(claim?.memberDesignationLabel as string)}
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
				/>
			</Stack>
			<Stack flexGrow={0} width={'auto'} rowGap={1.875}>
				<CompositeTypography
					label={tClientLabels('claim.labels.planName')}
					value={claim?.planName as string}
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
				/>
				<CompositeTypography
					label={tClientLabels('claim.labels.memberProviderLocation')}
					value={claim?.memberProviderLocation as string}
					variant={isDesktop ? 'bodyLink' : 'bodyLinkTablet'}
				/>
				<CompositeTypography
					label={tClientLabels('claim.labels.status')}
					value={claim?.claimStatus as string}
					children={<ClaimStatusBreadcrumbs claim={claim} />}
				/>
			</Stack>
		</Paper>
	);
};

export default ClaimMemberDetails;
