import {
	Select as SelectMui,
	SelectProps as SelectPropsMui,
} from '@mui/material';
import { ReactComponent as ArrowDownIcon } from 'assett/icons/arrow_down_icon.svg';
import { FC, forwardRef, useState } from 'react';
import styled from 'styled-components';

export type SelectProps = SelectPropsMui;

const StyledSelect = styled(SelectMui)({});

const Select: FC<SelectProps> = forwardRef((props, ref) => {
	const [selectOpen, setSelectOpen] = useState<boolean>(false);

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (props.onClick) {
			props.onClick(e);
		}

		setTimeout(() => {
			setSelectOpen(prev => !prev);
		}, 100);
	};

	return (
		<StyledSelect
			{...props}
			open={props.open ? props.open : selectOpen}
			onClick={handleClick}
			ref={ref}
			IconComponent={ArrowDownIcon}
		>
			{props.children}
		</StyledSelect>
	);
});

export default Select;
