import { useTranslate } from 'stylesHooks';
import Typography from 'styles/typography/Typograpy';
import { IBenefit } from 'types/Member';
import { formatPrice } from 'utils/utils';

const MemberValueBenefit = (props: { benefit: IBenefit }) => {
	const { t: tDbLabels } = useTranslate('dbLabels');
	const { coPay, coInsuranceAmount, maxAmount, memberBenefitLabel } =
		props.benefit;

	return (
		<Typography variant="body" minWidth={160} maxWidth={160} paddingRight={2.5}>
			{tDbLabels(memberBenefitLabel, {
				coPay: formatPrice(coPay, false),
				coInsuranceAmount: String(coInsuranceAmount),
				maxAmount: formatPrice(maxAmount, false),
			})}

			{/* {tDbLabels(memberBenefitLabel)
				// eslint-disable-next-line no-template-curly-in-string
				.replace('${coPay}', formatPrice(coPay, false))
				// eslint-disable-next-line no-template-curly-in-string
				.replace('${coInsuranceAmount}', String(coInsuranceAmount))
				// eslint-disable-next-line no-template-curly-in-string
				.replace('${maxAmount}', formatPrice(maxAmount, false))
				// eslint-disable-next-line no-template-curly-in-string
				.replace('${coPay}', formatPrice(coPay, false))} */}
		</Typography>
	);
};

export default MemberValueBenefit;
