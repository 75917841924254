import { Box as BoxMui, BoxProps as BoxPropsMui } from '@mui/material';

import styled from 'styled-components';

type BoxProps = BoxPropsMui;

const StyledBox = styled(BoxMui)<BoxProps>`
	margin-top: 20px;
	&::-webkit-scrollbar {
		height: 8px;
		width: 8px;
		border-radius: 6px;
		border: none;
	}
	&::-webkit-scrollbar-track {
		background-color: white;
		border-radius: 6px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 6px;
		height: 8px;
		width: 8px;
		background-color: #031434;
	}
`;

const TableBox: React.FC<BoxProps> = props => {
	return <StyledBox {...props}>{props.children}</StyledBox>;
};

export default TableBox;
