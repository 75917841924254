import TRANSLATION_EN from '../locales/en/translationEN.json';

export enum LANGUAGE_CODES {
	EN_GB = 'en-gb', //this is an exception for dayjs
	EN = 'en',
	IT = 'it',
	ES = 'es',
	DE = 'de',
}

export const LANGUAGES = [
	{ id: 'en-gb', label: 'English (UK)', localeDayjs: LANGUAGE_CODES.EN_GB },
	{ id: 'en-us', label: 'English (US)', localeDayjs: LANGUAGE_CODES.EN },
	{ id: 'it-it', label: 'Italiano', localeDayjs: LANGUAGE_CODES.IT },
	{ id: 'es-es', label: `Espa\u00f1ol`, localeDayjs: LANGUAGE_CODES.ES },
	{ id: 'de-de', label: 'Deutsch', localeDayjs: LANGUAGE_CODES.DE },
];

export const DEFAULT_LANGUAGE = {
	[LANGUAGE_CODES.EN]: {
		translation: { clientLabels: TRANSLATION_EN },
	},
	[LANGUAGE_CODES.IT]: {
		translation: { clientLabels: TRANSLATION_EN },
	},
	[LANGUAGE_CODES.ES]: {
		translation: { clientLabels: TRANSLATION_EN },
	},
	[LANGUAGE_CODES.DE]: {
		translation: { clientLabels: TRANSLATION_EN },
	},
};
