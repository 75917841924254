import { Chip as ChipMui, ChipProps as ChipPropsMui } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';
import palette from 'styles/theme/Palette';
import { fonts } from 'styles/theme/Typography';

type ChipProps = ChipPropsMui & { $selected?: boolean };

const StyledChip = styled(ChipMui)<ChipProps>`
	display: flex;
	justify-content: space-between;
	max-height: 32px;
	width: fit-content;
	gap: 20px;
	background-color: ${({ $selected }) =>
		`${$selected ? palette.primary1.main : palette.white.main}`};
	border: ${({ $selected }) =>
		`1px solid ${$selected ? palette.primary1.main : palette.grey4.main}`};
	color: ${({ $selected }) =>
		`${$selected ? palette.white.main : palette.primary1.main}`};
	font-size: 13px;
	font-weight: 600;
	letter-spacing: 0.2px;
	line-height: 18px;
	font-family: ${fonts.medium};
	padding: 0px 13px;
	cursor: pointer;
	&:hover {
		background-color: ${({ $selected }) =>
			`${$selected ? palette.primary1.main : palette.white.main}`};
		border: ${({ $selected }) =>
			`1px solid ${$selected ? palette.primary1.main : palette.grey3.main}`};
	}
`;
const Chip: React.FC<ChipProps> = props => {
	return (
		<StyledChip {...props} sx={{ '.MuiChip-label': { padding: 0 } }}>
			{props.children}
		</StyledChip>
	);
};
export default Chip;
