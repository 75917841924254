import { ThunkApiType } from 'types/storeTypes';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const searchLocationThunk = async (
	_: string,
	thunkAPI: ThunkApiType,
) => {
	const { searchLocationValue } = thunkAPI.getState().search;
	const {
		profile: { providerMasterId },
	} = thunkAPI.getState().user;
	const values = {
		name: searchLocationValue,
		provider_master: providerMasterId,
		size: 20,
	};

	try {
		const response = await authFetch.post('/provider/v2/search', values);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
