import { useTranslate } from '@tolgee/react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { MenuItem, Select, SelectItemText, TextField, Tooltip } from 'styles';
import { TextFieldProps as TextFieldPropsStyles } from 'styles/textfield/TextField';
import { breakpoints } from 'styles/theme/Breakpoints';
import palette from 'styles/theme/Palette';
import { TRANSLATION_KEYS } from 'utils/constants';

type TextFieldProps = TextFieldPropsStyles & {
	isCurrency?: boolean;
	tooltip?: string;
	type: 'textField' | 'autocomplete';
	options?: { value: string; descr: string }[];
};

const StyledTextField = styled(TextField)`
	width: 70px;
	height: 56px;
	justify-content: center;
	@media (max-width: ${breakpoints.values.lg - 1}px) {
		height: 42px;
	}
	.MuiInputBase-input {
		padding: 19px 10px 19px 10px;
	}
	.MuiInputBase-root {
		border: 1px solid ${palette.grey4.main};
	}
	.MuiInputBase-root.Mui-error {
		border: 1px solid ${palette.error.main};
	}
`;

const StyledSelect = styled(Select)`
	width: 70px;
	height: 56px;
	justify-content: center;
	background-color: transparent;
	@media (max-width: ${breakpoints.values.lg - 1}px) {
		height: 42px;
	}
	.MuiInputBase-input {
		background-color: transparent;
		padding: 19px 10px 19px 10px;
	}
	.MuiInputBase-root {
		border: 1px solid ${palette.grey4.main};
	}
	.MuiInputBase-root.Mui-error {
		border: 1px solid ${palette.error.main};
	}
`;

const PrescriptionField: React.FC<TextFieldProps> = props => {
	const tooltipRef = useRef<HTMLDivElement>(null);
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { tooltip, error, type, options, ...restProps } = props;
	const [onFocus, setOnFocus] = useState<boolean>(false);

	/***************************************** */
	//make tooltip disappear when field is not visible
	useEffect(() => {
		if (onFocus) {
			const intersectionObserver = new IntersectionObserver(entries => {
				// If intersectionRatio is 0, the target is out of view
				// and we do not need to do anything.
				if (entries[0].intersectionRatio <= 0.5) {
					setOnFocus(false);
					intersectionObserver.unobserve(tooltipRef.current!);
				}
			});

			if (tooltipRef.current) intersectionObserver.observe(tooltipRef.current);
		}
	}, [onFocus]);
	/***************************************** */

	return (
		<Tooltip
			ref={tooltipRef}
			title={
				tooltip
					? tClientLabels(`newClaim.prescription.eyesPrescriton.tooltips.${tooltip}`)
					: ''
			}
			arrow
			open={onFocus && error}
			placement="top"
		>
			<div>
				{
					// althougth this make no sense this fragment is needed to avoid a forward ref error
				}

				{type === 'textField' ? (
					<StyledTextField
						error={error}
						type="text"
						onFocus={() => setOnFocus(true)}
						{...restProps}
						onBlur={e => {
							props.onBlur && props.onBlur(e);
							setOnFocus(false);
						}}
					/>
				) : (
					<StyledSelect
						onFocus={() => setOnFocus(true)}
						onBlur={e => {
							setOnFocus(false);
						}}
						value={props.value}
						onChange={el => {
							if (!props.onChange) return;
							const event = { currentTarget: { value: el.target.value } };
							props.onChange(
								event as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
							);
						}}
						sx={{
							'.MuiFilledInput-input': { textOverflow: 'initial !important' },
							'.MuiSelect-icon': { display: 'none' },
						}}
						variant="filled"
						error={error}
						MenuProps={{
							sx: {
								'.MuiMenu-paper': { padding: 0 },
							},
						}}
					>
						<MenuItem value="" sx={{ height: '31.5px' }}>
							<SelectItemText>-</SelectItemText>
						</MenuItem>
						{(options || [])?.map(el => (
							<MenuItem key={el.value} value={el.value}>
								<SelectItemText>{tClientLabels(el.descr)}</SelectItemText>
							</MenuItem>
						))}
					</StyledSelect>
				)}
			</div>
		</Tooltip>
	);
};

export default PrescriptionField;
