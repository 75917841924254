import { getProfile } from 'feature/user/userSlice';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

import { resetSearch } from 'feature/search/searchSlice';
import { ThunkApiType } from 'types/storeTypes';

export const saveLocationThunk = async (_: string, thunkAPI: ThunkApiType) => {
	const { locationSelected } = thunkAPI.getState().user;

	const values = {
		selected: locationSelected,
	};

	try {
		const response = await authFetch.post('/provider/saveMemberProvider', values);

		if (response.status === 200) {
			thunkAPI.dispatch(getProfile(''));

			thunkAPI.dispatch(resetSearch());
		}
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
