import { changePage } from 'feature/payments/paymentSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { Pagination, Stack } from 'styles';

const PaymentClaimsFooter = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { isDesktop } = useDevice();
	const { pages, page } = useSelector(
		(store: RootState) => store.payment.paymentRun,
	);

	const handleChangePage = (
		event: React.ChangeEvent<unknown>,
		value: number,
	) => {
		event.preventDefault();
		dispatch(changePage(value));
	};

	return (
		<Stack
			direction={'row'}
			position={'relative'}
			sx={{ my: !!isDesktop ? 4.25 : 3 }}
			alignItems={'center'}
			justifyContent={'center'}
		>
			<Stack
				minHeight="24px"
				direction={'row'}
				flexGrow={1}
				sx={{
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{pages > 1 ? (
					<Pagination page={page} count={pages} onChange={handleChangePage} />
				) : null}
			</Stack>
		</Stack>
	);
};

export default PaymentClaimsFooter;
