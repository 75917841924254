import { thunkLoadingParams } from 'feature/progressIndicator/progressIndicatorSlice';
import { ThunkApiType } from 'types/storeTypes';
import { createPaymentsSearchValues } from 'utils/utils';
import authFetch, { checkForUnauthorizedResponse } from '../../utils/axios';

export const getPaymentsHistoryByProviderThunk = async (
	arg: thunkLoadingParams & { limit?: string },
	thunkAPI: ThunkApiType,
) => {
	let getPaymentsURL = `/payments`;

	const { page } = thunkAPI.getState().payments;
	const { sortingFields, sortingDirections } = thunkAPI.getState().form;
	const paymentsSearchValues = thunkAPI.getState().form;
	const {
		profile: { providerId },
	} = thunkAPI.getState().user;

	const limitParam = arg?.limit ? `&limit=${arg.limit}` : '';
	const searchParams = createPaymentsSearchValues(paymentsSearchValues);

	if (providerId) {
		getPaymentsURL =
			getPaymentsURL +
			`?page=${page}${limitParam}${
				searchParams ? searchParams : ''
			}&sortingFields=${sortingFields}&sortingDirections=${sortingDirections}`;

		try {
			const response = await authFetch.get(getPaymentsURL);
			return response.data;
		} catch (error: ThunkApiType) {
			return checkForUnauthorizedResponse(error, thunkAPI);
		}
	}
};
