import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
	Collections,
	IFilterCheckEligibility,
	defaultSortingField,
	sortingDirections,
} from 'types/Form';

const initialState: IFilterCheckEligibility = {
	errorCode: 0,
	errorMessage: '',
	collection: Collections.NONE,
	searchCheckElegibility: '',
	buttonSearchCheckElegibility: true,
	size: 10,
	search: false,
	inputFiscalCode: '',
	inputMemberId: '',
	inputFirstName: '',
	inputLastName: '',
	inputDateOfBirth: null,
	dateOfService: null,
	filterDateOfService: null,
	inputClaimId: '',
	inputExternalClaimId: '',
	inputClaimFirstName: '',
	inputClaimLastName: '',
	inputClaimDate: '',
	inputClaimStatus: '',
	inputPaymentRunId: null,
	inputTotalAmount: null,
	sortingFields: '',
	sortingDirections: sortingDirections.DESC,
};

const formSlice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		setCollection: (
			state: IFilterCheckEligibility,
			action: PayloadAction<Collections>,
		) => {
			state.sortingFields = defaultSortingField[action.payload];
			state.collection = action.payload;
		},
		handleSearchValuesForm: (state: IFilterCheckEligibility) => {
			state = { ...state };
		},
		handleSearchValueChange: (
			state: IFilterCheckEligibility | any,
			action: PayloadAction<React.ChangeEvent<HTMLInputElement>>,
		) => {
			const { name, value } = action.payload.target;
			state[name] = value;
		},
		handleChange: (state: any, action: any) => {
			state[action.payload.name] = action.payload.value;
		},
		setSearch: (
			state: IFilterCheckEligibility,
			{ payload }: PayloadAction<boolean>,
		) => {
			state.search = payload;
		},
		handleChangeClaimStatus: (
			state: IFilterCheckEligibility,
			action: PayloadAction<string>,
		) => {
			if (action.payload === '') {
				state['inputClaimStatus'] = '';
			} else {
				state['inputClaimStatus'] = action.payload;
			}
		},
		setSortingValues: (
			state: any,
			action: PayloadAction<{ name: string; value: string }>,
		) => {
			const { name, value } = action.payload;
			state.sortingFields = name;
			state.sortingDirections = value;
		},
		checkSearch: (state: IFilterCheckEligibility) => {
			if (
				state.searchCheckElegibility === 'inputByFiscalCode' &&
				!!state.inputFiscalCode
			) {
				state.buttonSearchCheckElegibility = false;
			}
		},
		resetInputDateOfBirth: state => {
			state.inputDateOfBirth = null;
		},
		resetInputMemberId: state => {
			state.inputMemberId = '';
		},
		resetForm: () => initialState,
	},
	extraReducers: builder => {},
});

export const {
	setCollection,
	handleSearchValueChange,
	resetForm,
	checkSearch,
	handleChange,
	handleChangeClaimStatus,
	setSearch,
	setSortingValues,
	resetInputDateOfBirth,
	resetInputMemberId,
} = formSlice.actions;
export default formSlice.reducer;
