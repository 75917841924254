import useDevice from 'hooks/DesktopDevice.hook';
import { Box, Stack } from 'styles';
import { IClaim } from 'types/Claim';
import { DocType } from 'types/Upload';
import SelectPrescription from '../SelectPrescription/SelectPrescription';
import DownloadAttestation from './DownloadAttestation';

type docLinksProps = {
	formData: IClaim;
	docList: DocType[];
};

const DocumentationLinks = ({ formData, docList }: docLinksProps) => {
	const { isTabletDevice } = useDevice();

	const width = isTabletDevice ? '100%' : '360px';

	return (
		<Stack direction={'row'} gap="40px" alignItems={'flex-end'}>
			{docList.find((doc: DocType) => doc === 'prescription') && (
				<Box width={width}>
					<SelectPrescription disabled={!!formData.prescription?.id} />
				</Box>
			)}
			{docList.find((doc: DocType) => doc === 'rxAttestation') && (
				<Box width={width}>
					<DownloadAttestation />
				</Box>
			)}
			<Box width={width}></Box>
		</Stack>
	);
};

export default DocumentationLinks;
