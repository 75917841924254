import {
	ListItemText as ListItemTextMui,
	ListItemTextProps as SelectItemTextPropsMui,
} from '@mui/material';
import * as React from 'react';
import palette from 'styles/theme/Palette';
import styled from 'styled-components';

type SelectItemTextProps = SelectItemTextPropsMui;

const StyledSelectItemText = styled(ListItemTextMui)`
	.MuiTypography-root {
		font-size: 13px;
		font-weight: 600;
		color: ${palette.primary.main};
	}
`;

const SelectItemText: React.FC<SelectItemTextProps> = props => {
	return (
		<StyledSelectItemText {...props}>{props.children}</StyledSelectItemText>
	);
};

export default SelectItemText;
