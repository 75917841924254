import { useTranslate } from 'stylesHooks';
import { ReactComponent as UploadIcon } from 'assett/icons/upload_arrow_icon.svg';
import { setDocumentTypeSelected } from 'feature/upload/uploadSlice';
import useDevice from 'hooks/DesktopDevice.hook';
import { useUploadFile } from 'hooks/uploadFile.hook';
import { ChangeEvent, DragEvent } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { Box, Button, Stack, Typography } from 'styles';
import { DocType } from 'types/Upload';
import { TRANSLATION_KEYS } from 'utils/constants';

type Props = {
	documentType: DocType;
};

const BeforeUpload = ({ documentType }: Props) => {
	const dispatch = useDispatch<AppDispatch>();
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);

	const { isTabletDevice } = useDevice();

	const uploadFileHandler = useUploadFile();

	const handleChange = (
		e: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
	) => {
		dispatch(setDocumentTypeSelected(documentType));
		uploadFileHandler.upload(e, documentType);
	};

	const handleDrag = function (evt: DragEvent<HTMLDivElement>) {
		uploadFileHandler.drag(evt);
	};

	const handleDrop = function (evt: DragEvent<HTMLDivElement>) {
		uploadFileHandler.drop(evt, handleChange);
	};

	const ButtonText = (
		<Typography
			sx={{ textDecoration: 'underline', textUnderlineOffset: '5px' }}
			variant="button"
		>
			{tClientLabels(`newClaim.upload.docNames.${documentType}`)}
		</Typography>
	);

	const ErrorText = uploadFileHandler.dragStatus.onError && (
		<Typography
			variant="caption"
			color={'error'}
			sx={{
				display: 'block',
				textTransform: 'none',
				width: isTabletDevice ? '100%' : '360px',
			}}
			mt={1}
		>
			{uploadFileHandler.dragStatus.errorMessage}
		</Typography>
	);

	return (
		<Box
			component={'label'}
			tabIndex={-1}
			onDragEnter={handleDrag}
			position={'relative'}
			sx={{
				minHeight: { sm: '210px', lg: '240px' },
				width: isTabletDevice ? '100%' : '360px',
			}}
		>
			<Button
				variant="upload"
				sx={{
					width: isTabletDevice ? '100%' : '360px',
					height: { xs: '64px', lg: '200px' },
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					gap: '30px',
					borderColor: uploadFileHandler.dragStatus.onError
						? theme => theme.palette.error.main
						: 'inherit',
				}}
				component={'label'}
				tabIndex={-1}
			>
				<UploadIcon width={48} height={48} />

				<Stack gap="20px" alignItems={'center'}>
					<Typography variant="value3" sx={{ textTransform: 'none' }}>
						{tClientLabels('uploadFileDialog.dragAndDrop')}
					</Typography>

					{ButtonText}
				</Stack>
				<input
					hidden
					id={`fileInput_${documentType}`}
					type="file"
					accept="*"
					onChange={handleChange}
				/>
			</Button>
			{uploadFileHandler.dragStatus.isActive && (
				<Box
					position="absolute"
					width="100%"
					height="100%"
					top={0}
					left={0}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				/>
			)}
			{ErrorText}
		</Box>
	);
};

export default BeforeUpload;
