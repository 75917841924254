import { thunkLoadingParams } from 'feature/progressIndicator/progressIndicatorSlice';
import { IConfiguration, OpeningsHour } from 'types/LocationInformation';
import { ThunkApiType } from 'types/storeTypes';
import authFetch, { checkForUnauthorizedResponse } from 'utils/axios';
import { getLocationInfo } from './locationInformationSlice';

export const getAvailableConfigThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: ThunkApiType,
) => {
	const PATH = '/provider/getAvailableConfig';
	try {
		const response = await authFetch.get(PATH);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getLocationInfoThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: ThunkApiType,
) => {
	const {
		profile: { providerId },
	} = thunkAPI.getState().user;
	const PATH = `/provider/${providerId}`;
	try {
		const response = await authFetch.get(PATH);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const saveLocationInfoThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: ThunkApiType,
) => {
	const {
		profile: { providerId },
	} = thunkAPI.getState().user;
	const {
		locationFields: { address1, phoneNumber, websiteUrl },
		openingsHour,
		currentConfiguration: { languages, services, brands, products },
	} = thunkAPI.getState().locationInformation;

	const openingsHourForBe = openingsHour.flatMap((element: OpeningsHour) =>
		element.days.map(day => ({
			weekDay: day + 1,
			openings: element.openings,
		})),
	);
	const PATH = `/provider`;
	const payload = {
		providerLocationId: providerId,
		address1: address1.trim(),
		phoneNumber: phoneNumber.trim(),
		websiteUrl,
		openingsHour: openingsHourForBe,
		currentLanguages: languages.map((el: IConfiguration) => el.code),
		currentServices: services.map((el: IConfiguration) => el.code),
		currentBrands: brands.map((el: IConfiguration) => el.code),
		currentProducts: products.map((el: IConfiguration) => el.code),
	};
	try {
		const response = await authFetch.post(PATH, payload);
		if (response.status === 200) {
			thunkAPI.dispatch(getLocationInfo({ showloader: true }));
		}
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getLocationLanguagesThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: ThunkApiType,
) => {
	const PATH = `/provider/languages`;
	try {
		const response = await authFetch.get(PATH);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getLocationProductsThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: ThunkApiType,
) => {
	const PATH = `/provider/products`;
	try {
		const response = await authFetch.get(PATH);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getLocationServicesThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: ThunkApiType,
) => {
	const PATH = `/provider/services`;
	try {
		const response = await authFetch.get(PATH);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};

export const getLocationBrandsThunk = async (
	arg: thunkLoadingParams,
	thunkAPI: ThunkApiType,
) => {
	const PATH = `/provider/brands`;
	try {
		const response = await authFetch.get(PATH);
		return response.data;
	} catch (error: ThunkApiType) {
		return checkForUnauthorizedResponse(error, thunkAPI);
	}
};
