import { ReactComponent as Basket } from 'assett/icons/basket.svg';
import { ReactComponent as Pencil } from 'assett/icons/pencil.svg';
import { ReactComponent as UploadCompleted } from 'assett/icons/upload_completed.svg';
import useDevice from 'hooks/DesktopDevice.hook';
import { Box, Button, IconButton, Stack, TextField, Typography } from 'styles';
import palette from 'styles/theme/Palette';
import { theme } from 'styles/theme/ThemeStyle';
import { fonts } from 'styles/theme/Typography';

interface UploadFileBoxProps {
	isEditMode: boolean;
	enableEditModeAction: () => void;
	enableDialogAction: () => void;
	handlerFileNameAction: () => void;
	handlerDownloadFile?: () => void;
	handlerFileName: (event: React.ChangeEvent<HTMLInputElement>) => void;
	fileName: string;
	newFileName?: string;
	canDownloadFile?: boolean;
	label: string;
}

const UploadFileBox: React.FC<UploadFileBoxProps> = ({
	enableEditModeAction,
	isEditMode,
	enableDialogAction,
	handlerFileNameAction,
	handlerFileName,
	handlerDownloadFile,
	fileName,
	newFileName,
	label,
	canDownloadFile,
}) => {
	const { isDesktop, isTabletDevice } = useDevice();

	return (
		<Stack
			direction={'column'}
			justifyContent={'stretch'}
			gap={'10px'}
			bgcolor={theme.palette.white.main}
			borderRadius={'4px'}
			px={'20px'}
			py={isDesktop ? '20px' : '10px'}
			height={isDesktop ? '200px' : '164px'}
			width={isTabletDevice ? '100%' : '360px'}
		>
			<Box display="flex" justifyContent="flex-end" gap="1.25rem">
				<IconButton onClick={enableEditModeAction}>
					<Pencil />
				</IconButton>
				<IconButton onClick={enableDialogAction}>
					<Basket />
				</IconButton>
			</Box>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
			>
				<Box
					display="flex"
					p={0}
					component={canDownloadFile ? Button : undefined}
					onClick={handlerDownloadFile}
				>
					<UploadCompleted />
				</Box>
				<Typography variant={isDesktop ? 'value1' : 'bodyLink'}>{label}</Typography>
			</Box>
			{isEditMode ? (
				<TextField
					onKeyDown={evt => {
						if (evt.key === 'Enter') {
							handlerFileNameAction();
						}
					}}
					onChange={handlerFileName}
					onBlur={handlerFileNameAction}
					autoFocus
					variant="standard"
					InputProps={{
						disableUnderline: true,
					}}
					sx={{
						'.MuiInput-input': {
							backgroundColor: `${palette.grey6.main}`,
							height: '14px',
							fontFamily: fonts.regular,
							fontWeight: 400,
							letterSpacing: '0.2px',
							fontSize: '11px',
							lineHeight: '16px',
							padding: 0,
						},
					}}
					value={newFileName || fileName}
				/>
			) : (
				<Typography
					overflow="hidden"
					textOverflow="ellipsis"
					textAlign="center"
					variant={'caption'}
					color={palette.grey2.main}
				>
					{fileName}
				</Typography>
			)}
		</Stack>
	);
};

export default UploadFileBox;
