import styled from 'styled-components';
import { Box } from 'styles';

export const Box130 = styled(Box)`
	width: 100%;
	max-width: 130px;
	min-width: 130px;
`;

export const Box100 = styled(Box)`
	width: 100%;
	max-width: 95px;
	min-width: 95px;
	text-align: center;
`;
