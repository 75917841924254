import useDialogConfirm from 'hooks/DialogConfirm.hook';
import { ChangeEvent, FC, useEffect } from 'react';
import { Box, Button, Stack, StackRadio, Typography } from 'styles';
import { IClaim, IClaimComponents, PRESCRIPTION_DEFAULT } from 'types/Claim';
import { IExam, IMaterials } from 'types/Member';

import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

import { useTranslate } from 'stylesHooks';
import { NO_MAT_NO_EXAM } from 'types/dialogtypes';
import { PageProps } from 'types/newClaimPageType';
import { TRANSLATION_KEYS } from 'utils/constants';

type Options = {
	label: string;
	value: number;
	enableIf: number[];
};

const getOptions = (eligible: IExam[] | IMaterials[], options: Options[]) =>
	options.filter(opt => {
		return opt.enableIf.find(optEnable => {
			return eligible.find(el => el.eligible && el.serviceId === optEnable);
		});
	});

const Page0: FC<PageProps> = props => {
	const { t: tClientLabels } = useTranslate(TRANSLATION_KEYS.CLIENT_LABELS);
	const { formData, setFormData, submitPage } = props;
	const { member } = useSelector((store: RootState) => store.member);

	const optionsExam = [
		{
			label: tClientLabels(`newClaim.exam.RoutineExam`),
			value: 3,
			enableIf: [3],
		},
		{
			label: tClientLabels(`newClaim.exam.NoExam`),
			value: -1,
			enableIf: [1, 4, 5, 2],
		},
	];

	const optionsMaterials = [
		{
			label: tClientLabels(`newClaim.materials.fl`),
			value: 4,
			enableIf: [4],
		},
		{
			label: tClientLabels(`newClaim.materials.fo`),
			value: 2,
			enableIf: [2, 4],
		},
		{
			label: tClientLabels(`newClaim.materials.lo`),
			value: 1,
			enableIf: [1, 4],
		},
		{
			label: tClientLabels(`newClaim.materials.contacts`),
			value: 5,
			enableIf: [5],
		},
		{
			label: tClientLabels(`newClaim.materials.noMat`),
			value: -2,
			enableIf: [3],
		},
	];

	const benefits = [
		...(member?.benefit ? member.benefit.exam : []),
		...(member?.benefit ? member.benefit.materials : []),
	];

	const exam = getOptions(benefits, optionsExam);

	const materials = getOptions(benefits, optionsMaterials);

	const lensTypeRequired = [4, 1];

	const onChange: (
		event: ChangeEvent<HTMLInputElement>,
		value: string,
	) => void = (event, value) => {
		const newComponents = refreshComponents({
			oldComponents: formData.amount.components,
			exam: formData.exam,
			materials: formData.materials,
			[event.target.name]: Number(value),
		});

		setFormData((prev: IClaim) => ({
			...prev,
			[event.target.name]: Number(value),
			amount: { ...prev.amount, components: newComponents },
		}));
	};

	useEffect(() => {
		if (exam.length === 1)
			setFormData((prev: IClaim) => ({
				...prev,
				exam: exam[0].value,
			}));
		// eslint-disable-next-line
	}, [exam.length]);

	useEffect(() => {
		if (materials.length === 1)
			setFormData((prev: IClaim) => ({
				...prev,
				materials: materials[0].value,
			}));
		// eslint-disable-next-line
	}, [materials.length]);

	const dialogConfirm = useDialogConfirm();

	return (
		<>
			<Stack gap={'20px'}>
				<Typography variant="title6">
					{tClientLabels('newClaim.selectedPage.sectionTitile.selectType')}
				</Typography>
				{exam.length > 1 || (exam.length === 1 && exam[0].value > 0) ? (
					<StackRadio
						name="exam"
						onChange={onChange}
						value={formData.exam}
						label={tClientLabels('newClaim.exam.title')}
						options={exam}
					/>
				) : null}
				{materials.length > 1 ||
				(materials.length === 1 && materials[0].value > 0) ? (
					<StackRadio
						name="materials"
						onChange={(event, value) => {
							onChange(event, value);
							if (!lensTypeRequired.includes(formData.materials))
								setFormData((prev: IClaim) => ({
									...prev,
									lensType: undefined,
								}));

							if (event.currentTarget.value === '5')
								//contacts have less fields
								setFormData((prev: IClaim) => ({
									...prev,
									newPrescriptionContent: {
										...PRESCRIPTION_DEFAULT,
									},
								}));
						}}
						value={formData.materials}
						label={tClientLabels('newClaim.materials.title')}
						options={materials}
					/>
				) : null}
				{lensTypeRequired.includes(formData.materials) ? (
					<StackRadio
						name="lensType"
						onChange={onChange}
						value={formData.lensType ?? 0}
						label={tClientLabels('newClaim.lensType.title')}
						options={[
							{
								label: tClientLabels(`newClaim.lensType.sv`),
								value: 1,
							},
							{
								label: tClientLabels(`newClaim.lensType.bi`),
								value: 2,
							},
							{
								label: tClientLabels(`newClaim.lensType.tri`),
								value: 3,
							},
							{
								label: tClientLabels(`newClaim.lensType.pro`),
								value: 4,
							},
						]}
					/>
				) : null}
			</Stack>
			<Box alignItems="start" sx={{ mt: '30px' }}>
				<Button
					disabled={
						!(
							formData.exam &&
							formData.materials &&
							(!lensTypeRequired.includes(formData.materials) || formData.lensType)
						)
					}
					variant="contained"
					sx={{ mt: '10px' }}
					onClick={() => {
						if (formData.exam === -1 && formData.materials === -2) {
							dialogConfirm.open(NO_MAT_NO_EXAM);
						} else {
							submitPage!();
						}
					}}
				>
					{tClientLabels('newClaim.selectedPage.proceed')}
				</Button>
			</Box>
		</>
	);
};

const refreshComponents = (inputs: {
	oldComponents: IClaimComponents[];
	exam: number;
	materials: number;
}) => {
	const { oldComponents, exam, materials } = inputs;

	const newComponents: IClaimComponents[] = [];

	const addOrRemoveComponent = (serviceId: 1 | 2 | 3 | 5) => {
		const componentIndex = oldComponents.findIndex(
			el => el.serviceId === serviceId,
		);
		if (componentIndex !== -1) {
			newComponents.push(oldComponents[componentIndex]);
		} else {
			newComponents.push({
				retailAmount: 0,
				total: 0,
				contractualAdjustmentAmount: 0,
				memberPayAmount: 0,
				planPayAmount: 0,
				serviceId: serviceId,
				discountedAmount: 0,
			});
		}
	};
	if (exam === 3) {
		addOrRemoveComponent(3);
	}

	switch (materials) {
		case 1:
			addOrRemoveComponent(1);
			break;
		case 2:
			addOrRemoveComponent(2);
			break;
		case 4:
			addOrRemoveComponent(2);
			addOrRemoveComponent(1);
			break;
		case 5:
			addOrRemoveComponent(5);
			break;
	}

	return newComponents;
};

export default Page0;
