import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IState } from 'types/Member';
import { getMemberDetailsThunk } from './memberThunk';

const initialState: IState = {
	errorCode: 0,
	errorMessage: '',
	member: null,
	pending: false,
};

export const getMemberDetails = createAsyncThunk(
	'member/getMemberDetails',
	getMemberDetailsThunk,
);

const memberSlice = createSlice({
	name: 'member',
	initialState,
	reducers: {
		resetMember: () => initialState,
	},
	extraReducers: builder => {
		builder.addCase(
			getMemberDetails.fulfilled,
			(state: IState, response: any) => {
				const { payload, meta } = response;
				state.member = { ...payload, memberId: meta.arg.memberId };
			},
		);
	},
});

export const { resetMember } = memberSlice.actions;
export default memberSlice.reducer;
