import { useTheme } from 'stylesHooks';
import { ReactComponent as CloseIcon } from 'assett/icons/close_icon.svg';
import FileRenderer from 'components/commons/FileRenderer/FileRenderer';
import {
	getAttachmentPreview,
	resetSelectedAttachment,
} from 'feature/attachments/attachmentsSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { Box, BoxOverflow, IconButton, Stack, Typography } from 'styles';
import { IAttachment } from 'types/Attachment';

type Props = {
	doc: IAttachment;
};

const PrescriptionPreview = ({ doc }: Props) => {
	const { attachmentPreview } = useSelector(
		(state: RootState) => state.attachments,
	);
	const dispatch = useDispatch<AppDispatch>();
	const theme = useTheme();
	const handleReset = () => {
		dispatch(resetSelectedAttachment());
	};

	useEffect(() => {
		dispatch(getAttachmentPreview({}));
	}, [dispatch]);

	return (
		<Stack
			px={'20px'}
			py={{
				xl: '20px',
				lg: '20px',
				md: '14px',
				sm: '14px',
				xs: '14px',
			}}
		>
			<Stack
				direction={'row'}
				justifyContent={'space-between'}
				py={'20px'}
				position={'absolute'}
				top={{ xl: '63px', lg: '63px', md: '53px', sm: '53px', xs: '53px' }}
				width={{
					xl: '800px',
					lg: '800px',
					md: '728px',
					sm: '728px',
					xs: '728px',
				}}
				zIndex={10}
				bgcolor={theme.palette.white.main}
				borderTop={`1px solid ${theme.palette.grey3.main}`}
			>
				<Box width={'84px'}></Box>
				<Typography variant="bodyLink">{doc.fileName}</Typography>
				<Stack direction={'row'} width={'84px'} justifyContent={'flex-end'}>
					<IconButton onClick={handleReset} disableRipple>
						<CloseIcon />
					</IconButton>
				</Stack>
			</Stack>
			{attachmentPreview && (
				<BoxOverflow
					variant="y"
					sx={{
						marginTop: { xl: '44px', lg: '44px', md: '50px', sm: '50px', xs: '50px' },
						maxHeight: {
							xl: '650px',
							lg: '650px',
							md: '468px',
							sm: '468px',
							xs: '468px',
						},
						border: `1px solid ${theme.palette.grey5.main}`,
					}}
				>
					<FileRenderer
						mime={doc.mime}
						url={attachmentPreview}
						name={doc.fileName}
						isPrescription
					/>
				</BoxOverflow>
			)}
		</Stack>
	);
};

export default PrescriptionPreview;
